import { element } from 'prop-types'
import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'

const structureBaseImageMapper = ({ image, events }) => {
  const { currentViewDisplayTime, nextViewDisplayTime } = getTeacherViewEventDisplayTime(events?.view, {
    elementId: image?.id
  })

  return {
    ...image,
    currentViewDisplayTime,
    nextViewDisplayTime
  }
}

export default structureBaseImageMapper
