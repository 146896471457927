import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getAllCategoriesUseCase from '../../../useCase/memoryBoxUseCase/getAllCategoryUseCase'
import addCategoryUseCase from '../../../useCase/memoryBoxUseCase/addCategoryUseCase'
import deleteCategoryUseCase from '../../../useCase/memoryBoxUseCase/deleteCategoryUseCase'
import editCategoryUseCase from '../../../useCase/memoryBoxUseCase/editCategoryUseCase'
import { baseAsyncThunk } from '../../baseAsyncThunk'

const initialState = {
  allCategoriesLoading: false,
  loading: false,
  categories: [],
  error: ''
}

export const getAllCategories = baseAsyncThunk('memoryBoxCategorySlice/getAllCategories', () => {
  return getAllCategoriesUseCase()
})

export const addCategory = baseAsyncThunk('memoryBoxCategorySlice/addCategory', ({ title, color }) => {
  return addCategoryUseCase({ title, color })
})

export const deleteCategory = createAsyncThunk('memoryBoxCategorySlice/deleteCategory', id => {
  return deleteCategoryUseCase(id)
})

export const editCategory = baseAsyncThunk(
  'memoryBoxCategorySlice/editCategory',
  ({ id, title, color, sideToShow, orderOfDisplay }) => {
    return editCategoryUseCase({ id, title, color, sideToShow, orderOfDisplay })
  }
)

const memoryBoxCategorySlice = createSlice({
  name: 'memoryBoxCategory',
  initialState,
  reducers: {
    getCategoryById: (state, action) => {
      state.selectedCategory = state.categories.find(item => {
        return item.id === action.payload.categoryId
      })
    }
  },
  extraReducers: builder => {
    //get all categories
    builder.addCase(getAllCategories.pending, state => {
      state.allCategoriesLoading = true
    })
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.allCategoriesLoading = false
      state.categories = action.payload
    })
    builder.addCase(getAllCategories.rejected, (state, action) => {
      state.allCategoriesLoading = false
      state.error = action.error.message
    })

    //add Category
    builder.addCase(addCategory.pending, state => {
      state.loading = true
    })
    builder.addCase(addCategory.fulfilled, state => {
      state.loading = false
    })
    builder.addCase(addCategory.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //remove Category
    builder.addCase(deleteCategory.pending, state => {
      state.loading = true
    })
    builder.addCase(deleteCategory.fulfilled, state => {
      state.loading = false
    })
    builder.addCase(deleteCategory.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //edit category
    builder.addCase(editCategory.pending, state => {
      state.loading = true
    })
    builder.addCase(editCategory.fulfilled, (state, action) => {
      state.selectedCategory = action.payload
      state.loading = false
    })
    builder.addCase(editCategory.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { getCategoryById } = memoryBoxCategorySlice.actions

export default memoryBoxCategorySlice.reducer
