import Modal from 'components/Modal'
import PrimaryButton from 'components/PrimaryButton/PrimaryButton'
import TranslatedText from 'components/TranslatedText/TranslatedText'
import ColorsContext from 'contexts/ColorsContext'
import useTranslatedText from 'hooks/useTranslatedText'
import { useContext } from 'react'
import { useNavigate } from 'react-router'

const SubscriptionUpgradeModal = ({ closeModal }: { closeModal: () => void }) => {
  const { dir } = useTranslatedText() // rtl, ltr
  // @ts-ignore
  const { colors } = useContext(ColorsContext)
  const navigate = useNavigate()

  return (
    <Modal closeModal={closeModal} isCenter>
      <div className='tw-px-4 tw-max-w-screen-xxs tw-container'>
        <div
          className='tw-flex tw-flex-col tw-items-center tw-w-full tw-rounded-[50px] tw-p-4 tw-gap-3 tw-text-center'
          style={{ background: colors['C609'] }}
        >
          <img
            className='tw-w-3/5 tw-aspect-square tw-object-contain'
            src={`${window.location.origin}/assets/images/user-subscribing.png`}
            alt=''
          />
          <h3 className='tw-text-2xl tw-font-bold' dir={dir} style={{ color: colors['C593'] }}>
            <TranslatedText translationKey='subscription.upgrade.modal.title' />
          </h3>
          <p className='tw-text-md tw-font-semibold' dir={dir} style={{ color: colors['C593'] }}>
            <TranslatedText translationKey='subscription.upgrade.modal.description' />
          </p>
          <div className='d-flex justify-content-center'>
            <PrimaryButton
              onClick={() => {
                navigate('/payment')
                closeModal()
              }}
            >
              <TranslatedText translationKey={'upgrade.now'} />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SubscriptionUpgradeModal
