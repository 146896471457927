import { max, min } from 'lodash'
import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'

const structureBaseTitleMapper = ({ title, events }) => {
  const { currentViewDisplayTime, nextViewDisplayTime } = getTeacherViewEventDisplayTime(events?.view, {
    elementId: title?.id
  })

  const startTimes = events?.word?.filter(it => it?.sentenceId === title?.id).map(it => parseInt(it?.startTime) || 0)
  const endTimes = events?.word?.filter(it => it?.sentenceId === title?.id).map(it => parseInt(it?.endTime) || 0)

  return {
    ...title,
    isRoboTeacher: true,
    startWordEventsTime: min(startTimes),
    endWordEventsTime: max(endTimes),
    wordEvents: events?.word?.filter(it => it?.sentenceId === title?.id),
    currentViewDisplayTime,
    nextViewDisplayTime
  }
}

export default structureBaseTitleMapper
