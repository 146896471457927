import { useAppDispatch } from './useAppDispatch'
import { isFulfilled, isRejected } from '@reduxjs/toolkit'
import waitForOnline from 'util/waitForOnline'
import * as Sentry from '@sentry/react'
import waitForTime from 'util/waitForTime'
import { useEffect, useRef } from 'react'

const maxRetryCount = 2

const useEnhancedDispatch = () => {
  const unmounted = useRef(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])

  const retry = async (thunk: any, arg = {}, res: any, retryCount: number) => {
    const handleOnline = async (delay: number, considerMaxRetryCount: boolean) => {
      if (considerMaxRetryCount) {
        retryCount++
      }

      // Wait a little longer to ensure network stability
      await waitForTime(delay)
      // If component unmounted, set the retryCount to maxRetryCount so it won't retry again
      retryCount = unmounted.current ? maxRetryCount : retryCount
      return await enhancedDispatch(thunk, arg, { retry: true }, retryCount)
    }

    if (!navigator.onLine) {
      // Retries within 3000 ms after the user comes back online.
      await waitForOnline()
      return await handleOnline(3000, false)
    } else if (retryCount < maxRetryCount) {
      // Retries within 1000 (limited times)
      return await handleOnline(1000, true)
    } else {
      return res
    }
  }

  // Return the promise from dispatch so `.then()` can be chained
  const enhancedDispatch: any = async (thunk: any, arg = {}, options = { retry: true }, retryCount = 0) => {
    arg = { ...arg, retryCount }
    const res = await dispatch(thunk(arg))

    if (options.retry && isRejected(res) && res?.payload?.status === 'ERR_NETWORK') {
      return await retry(thunk, arg, res, retryCount)
    } else {
      if (isFulfilled(res) && retryCount > 0) {
        // Send sentry log message for successful requests after retries
        Sentry.captureMessage(`Request succeeded after ${retryCount} retries`, {
          level: 'info',
          extra: {
            actionType: res?.type
          }
        })
      }
      return res
    }
  }
  return { enhancedDispatch }
}

export default useEnhancedDispatch
