// baseAsyncThunk.js
import { createAsyncThunk } from '@reduxjs/toolkit'
import { registerThunk } from './thunkActions'
import { globalModalEnums } from 'enums/globalEnums/globalEnums'
import { setCurrentOpenModal } from './features/modalSlice'

export const baseAsyncThunk = (typePrefix, payloadCreator, options = {}) => {
  // Set the default value of `retry` option to `true`
  options = { retry: true, ...options }

  const thunk = createAsyncThunk(typePrefix, async (arg, thunkAPI) => {
    try {
      return await payloadCreator(arg, thunkAPI)
    } catch (error) {
      if (error.status === 403) {
        thunkAPI.dispatch(setCurrentOpenModal(globalModalEnums.subscriptionUpgrade))
      }

      return thunkAPI.rejectWithValue(
        {
          error: error.message,
          status: error.code,
          response: error.response?.data
        },
        {
          retry: options?.retry,
          thunkId: typePrefix
        }
      )
    }
  })

  // Auto-register on creation
  registerThunk(typePrefix, thunk)
  return thunk
}
