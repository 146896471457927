import structureBaseTitleMapper from './structureBaseTitleMapper'
import structureBaseImageMapper from './structureBaseImageMapper'
import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'

const structureGeneralTemplateMapper = ({ item, events }) => {
  const { currentViewDisplayTime: currentTemplateViewDisplayTime, nextViewDisplayTime: nextTemplateViewDisplayTime } =
    getTeacherViewEventDisplayTime(events?.view, {
      templateId: item?.id
    })

  return {
    ...item,
    data: {
      cards: item.data.cards.map(card => {
        const { currentViewDisplayTime: currentCardViewDisplayTime, nextViewDisplayTime: nextCardViewDisplayTime } =
          getTeacherViewEventDisplayTime(events?.view, {
            templateId: item?.id,
            cardId: card?.id
          })
        return {
          ...card,
          title: card?.title ? structureBaseTitleMapper({ title: card?.title, events }) : null,
          pictureText: card?.pictureText ? structureBaseTitleMapper({ title: card?.pictureText, events }) : null,
          picture: structureBaseImageMapper({ image: card?.picture, events }),
          subjects: card.subjects.map(subject => {
            const {
              currentViewDisplayTime: currentSubjectViewDisplayTime,
              nextViewDisplayTime: nextSubjectViewDisplayTime
            } = getTeacherViewEventDisplayTime(events?.view, {
              templateId: item?.id,
              subjectId: subject?.id
            })

            return {
              ...subject,
              texts: subject.texts.map(text => {
                return {
                  ...text,
                  text: structureBaseTitleMapper({ title: text?.text, events })
                }
              }),
              currentViewDisplayTime: currentSubjectViewDisplayTime,
              nextViewDisplayTime: nextSubjectViewDisplayTime
            }
          }),
          currentViewDisplayTime: currentCardViewDisplayTime,
          nextViewDisplayTime: nextCardViewDisplayTime
        }
      })
    },
    currentViewDisplayTime: currentTemplateViewDisplayTime,
    nextViewDisplayTime: nextTemplateViewDisplayTime
  }
}

export default structureGeneralTemplateMapper
