import { isRejected } from '@reduxjs/toolkit'

const maxRetryCount = 2

const retryMiddleware = storeAPI => next => action => {
  const reachedMaxRetryCount = action?.meta?.arg?.retryCount >= maxRetryCount
  const isNetworkError = action.payload?.status === 'ERR_NETWORK'

  if (isRejected(action) && isNetworkError && !reachedMaxRetryCount) {
    return
  }
  return next(action)
}

export default retryMiddleware
