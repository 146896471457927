import { useContext, useEffect, useMemo, useState } from 'react'
import HelpButton from '../../../components/HelpButton/HelpButton'
import ExerciseStatistics from '../../../components/ExerciseStatistics/ExerciseStatistics'
import GameStatistics from '../../../components/GameStatistics/GameStatistics'
import colorsContext from '../../../contexts/ColorsContext'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearSectionStatisticsStates,
  getUnitGameStatistics,
  getUnitStatistics
} from '../../../redux/features/statisticsSlice'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import { FINISH_SECTION, START_SECTION } from '../../../enums/studyLogEnums/studyLogEnums'
import useLog from '../../../hooks/logHooks/useLog'
import UnitDataStorage from '../../../classes/UnitDataStorage'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import useSectionEvents from '../../../hooks/sectionHooks/useSectionEvents'
import useEnhancedDispatch from '../../../hooks/useDispatchWrapper'
import { isEmpty } from 'lodash'
import RetryServiceButton from 'atomicComponents/molecules/RetryServiceButton/RetryServiceButton'

const StatisticsSection = () => {
  const { colors } = useContext(colorsContext)
  const { unit } = useParams()
  const dispatch = useDispatch()
  const { enhancedDispatch } = useEnhancedDispatch()
  const { unitExerciseData, unitGameData, error } = useSelector(state => state.statistics)
  const { logPending } = useSelector(state => state.studyLog)
  const { navigateToNextSection } = useSectionEvents()
  const { sendServerLog } = useLog()
  const [loading, setLoading] = useState(true)

  const unitData = useMemo(() => {
    const unitDataStorage = new UnitDataStorage()
    return unitDataStorage.getUnitData()
  }, [])

  const fetchData = () => {
    setLoading(true)
    enhancedDispatch(getUnitStatistics, { unitId: unit, studyType: unitData?.studyStatus }).then(() =>
      setLoading(false)
    )
    enhancedDispatch(getUnitGameStatistics, { unitId: unit, studyType: unitData?.studyStatus })
  }

  useEffect(() => {
    sendServerLog(START_SECTION, null, () => {
      setTimeout(() => {
        sendServerLog(FINISH_SECTION, null, () => {
          setTimeout(() => {
            fetchData()
          }, 300)
        })
      }, 300)
    })

    return () => {
      dispatch(clearSectionStatisticsStates())
    }
  }, [])

  if (!loading && unitExerciseData?.exercises?.length === 0 && isEmpty(unitGameData) && Boolean(error)) {
    return <RetryServiceButton onClick={fetchData} />
  }

  return (
    <div className='py-3'>
      <div className='d-flex justify-content-end container-fluid'>
        <div className='me-3 me-md-5 ms-2'>
          <HelpButton color={colors['C2']} pageType={pageGuidEnums.STATISTICS_SECTION} />
        </div>
      </div>
      {!loading ? (
        <>
          <ExerciseStatistics data={unitExerciseData} />
          <GameStatistics data={unitGameData} />
        </>
      ) : (
        <div className='d-flex align-items-center justify-content-center' style={{ height: 'calc(100dvh - 263px)' }}>
          <div
            className='spinner spinner-border'
            style={{ borderColor: colors['C2'], borderRightColor: 'transparent' }}
          />
        </div>
      )}
      <div className='container-fluid d-flex justify-content-center'>
        <PrimaryButton loading={logPending} onClick={navigateToNextSection}>
          Done
        </PrimaryButton>
      </div>
    </div>
  )
}

export default StatisticsSection
