import AISvg from 'icons/AISvg'
import { useContext, useState } from 'react'
import colorsContext from 'contexts/ColorsContext'
import componentStyle from './ExerciseAIAssistantButton.module.scss'
import ExerciseAIAssistantModal from '../ExerciseAIAssistantModal/ExerciseAIAssistantModal'
import SectionLockedIcon from 'atomicComponents/atoms/SectionLockedIcon/SectionLockedIcon'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { setCurrentOpenModal } from 'redux/features/modalSlice'
import { globalModalEnums } from 'enums/globalEnums/globalEnums'
import useAppUser from 'hooks/useAppUser'

const ExerciseAIAssistantButton = ({ exerciseItemId, correctAnswer, userAnswer }) => {
  const { colors } = useContext(colorsContext)
  const [showAssistant, setShowAssistant] = useState(false)
  const { userHasPlan } = useAppUser()
  const dispatch = useAppDispatch()

  return (
    <>
      <button
        className={componentStyle.button}
        style={{ background: colors['C160'] }}
        onClick={() => {
          if (userHasPlan) {
            setShowAssistant(true)
          } else {
            dispatch(setCurrentOpenModal(globalModalEnums.subscriptionUpgrade))
          }
        }}
      >
        {!userHasPlan && (
          <div className='tw-absolute -tw-top-1 -tw-right-1'>
            <SectionLockedIcon nonButton color={colors['C233']} />
          </div>
        )}
        <AISvg color={colors['C233']} />
      </button>
      {showAssistant && (
        <ExerciseAIAssistantModal
          exerciseItemId={exerciseItemId}
          correctAnswer={correctAnswer}
          userAnswer={userAnswer}
          closeModal={() => setShowAssistant(false)}
        />
      )}
    </>
  )
}

export default ExerciseAIAssistantButton
