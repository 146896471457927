import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setGlobalAudioId } from '../../redux/features/audioSlice'
import { v4 as uuidv4 } from 'uuid'
import getCachedMediaUrl from '../../operations/getCachedMediaUrl'
import { isEmpty, isNumber, throttle } from 'lodash'
import * as ogv from 'ogv'
import { Howl } from 'howler'
import usePageVisibility from '../usePageVisibility'
import { isMobile } from 'react-device-detect'

ogv.OGVLoader.base = '/assets/js/ogvjs-1.8.9'

function useHowlAudioPlayer({
  audioUrl,
  preloadOnRender,
  autoplay,
  audioElementId,
  format = 'mp3',
  stopOnPause = false,
  defaultSeek = 0,
  group = true, // When group is true, it will be considered as a sound in global group; Only one sound in a group could be played at the same time
  onPlayed = () => {},
  onEnded = () => {},
  disabled = false,
  preventResetVoiceOnEnd = false,
  accent
}) {
  const pageVisibilityHidden = usePageVisibility()
  const loadTypes = useMemo(
    () => ({
      notLoaded: 0,
      loading: 1,
      loadSuccess: 2,
      loadError: 3
    }),
    []
  )

  const sound = useRef(null)
  // Generate unique sound id for this component (purpose: play one sound in a group at the same time)
  // With prefix "ACI_" => Audio Component Id
  const soundId = useRef(`ACI_${uuidv4()}`)

  const isMounted = useRef(true) // New ref to keep track of component's mount status

  const [duration, setDuration] = useState(0)
  const [curTime, setCurTime] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [playerStatus, setPlayerStatus] = useState('STOP')
  const [loading, setLoading] = useState(false)
  const [loadingState, setLoadingState] = useState(loadTypes.notLoaded)
  const startTime = useRef(0)
  const waitingToPlay = useRef(false)

  const { currentGlobalAudioId: gSoundId } = useSelector(state => state.audio)
  const dispatch = useDispatch()

  useEffect(() => {
    soundId.current = `ACI_${uuidv4()}`
  }, [audioUrl, accent])

  useEffect(() => {
    return () => {
      // Set unmounted on cleanup
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (isMobile && pageVisibilityHidden && playing) {
      setPlaying(false)
    }
  }, [pageVisibilityHidden])

  const onPlayHowl = id => {
    if (!isMounted.current) {
      sound.current?.stop()
    }

    if (startTime.current > 0) {
      setAudioSeekTime(startTime.current)
      startTime.current = 0
    }

    onPlayed(isPlaying => setPlaying(isPlaying))
    setPlayerStatus('START_PLAYING')

    if (group) {
      dispatch(setGlobalAudioId(soundId.current))
    }

    const updateCurTime = throttle(() => {
      // Update current time state
      if (sound.current) {
        setCurTime(sound.current.seek())
      }
    }, 200)

    // Updated Request Animation Frame
    let updatedRaf = undefined

    // Define a function to be run on every animation frame
    const onAnimationFrame = () => {
      if (!isMounted.current) {
        sound.current?.stop()
      }
      // If the howl is still playing
      else if (sound.current && sound.current.playing()) {
        updateCurTime()
        // Continue processing updates
        updatedRaf = requestAnimationFrame(onAnimationFrame)
      }
      // If the howl is no longer playing
      else {
        // Stop processing updates
        if (updatedRaf) {
          cancelAnimationFrame(updatedRaf)
        }
      }
    }

    // Start processing updates
    updatedRaf = requestAnimationFrame(onAnimationFrame)
  }

  const onPauseHowl = () => {
    setPlayerStatus('PAUSED')
  }

  const onEndHowl = () => {
    onEnded(isPlaying => setPlaying(isPlaying))
    setPlaying(false)
    setPlayerStatus('ENDED')
    if (preventResetVoiceOnEnd) {
      sound.current.seek(sound.current.duration())
    }
  }

  const onLoadHowl = autoplay => {
    setLoading(false)
    if (isMounted.current && sound.current) {
      setAudioSeekTime(defaultSeek)
      if (!disabled && (autoplay || waitingToPlay.current)) {
        setTimeout(() => {
          sound.current?.play()
          waitingToPlay.current = false
        }, 180)
      }
      setDuration(sound.current.duration())
      setCurTime(sound.current.seek())
      setPlayerStatus('LOADED')
      setLoadingState(loadTypes.loadSuccess)
    }
  }

  const onErrorHowl = () => {
    setLoading(false)
    setLoadingState(loadTypes.loadError)
    waitingToPlay.current = false
  }

  const createSound = async autoplay => {
    if (!isEmpty(audioUrl)) {
      setLoadingState(loadTypes.loading)

      if (sound.current) {
        sound.current.unload()
        sound.current = null
      }

      try {
        setLoading(true)
        const res = await getCachedMediaUrl(audioUrl)

        if (res && isMounted.current) {
          // Check if component is still mounted
          sound.current = new Howl({
            src: res,
            preload: true,
            format,
            html5: false,
            onplay: id => onPlayHowl(id),
            onpause: onPauseHowl,
            onend: onEndHowl,
            onload: () => onLoadHowl(autoplay),
            onseek: () => setCurTime(sound.current ? sound.current.seek() : 0),
            onerror: onErrorHowl
          })
        }
      } catch (err) {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (audioUrl && preloadOnRender) {
      createSound(autoplay)
    }
  }, [audioUrl, preloadOnRender, accent])

  useEffect(() => {
    if (sound.current) {
      // effect cleanup
      return () => {
        sound.current?.stop()
        sound.current?.unload()

        setDuration(0)
        setCurTime(0)
        setPlaying(false)
        setPlayerStatus('STOP')
        sound.current = null
        setLoadingState(loadTypes.notLoaded)
      }
    }
  }, [sound.current, audioElementId, audioUrl, accent])

  useEffect(() => {
    if (group && gSoundId !== soundId.current && sound.current) {
      if (stopOnPause) {
        sound.current.stop()
      } else {
        sound.current.pause()
      }
      setLoading(false)
      setPlaying(false)
    }
  }, [group, gSoundId])

  useEffect(() => {
    if (!disabled) {
      if (playing && isMounted.current) {
        onPlay()
      } else {
        onPause()
      }
    }
  }, [playing])

  useEffect(() => {
    if (playerStatus === 'START_PLAYING' && isNumber(curTime)) setPlayerStatus('PLAYED')
  }, [curTime])

  const setAudioSeekTime = newTime => {
    if (sound.current && isNumber(newTime) && newTime !== sound.current.seek()) {
      const x = Math.max(0, Math.min(sound.current.duration(), newTime))
      sound.current.seek(x)
    }
  }

  const onPlay = async () => {
    if (loadingState === loadTypes.notLoaded) {
      createSound(true)
    } else if (loadingState === loadTypes.loadSuccess) {
      sound.current?.play()
    } else if (loadingState === loadTypes.loading) {
      waitingToPlay.current = true
    } else {
      setPlaying(false)
    }
  }

  const onPause = () => {
    if (sound.current && sound.current.playing()) {
      if (stopOnPause) {
        sound.current.stop()
      } else {
        sound.current.pause()
      }
    }
  }

  const setPlayingFunc = (isPlaying, playTime) => {
    if (typeof playTime === 'number') {
      startTime.current = playTime
    }
    setPlaying(isPlaying)
  }

  return {
    curTime,
    duration,
    playing: sound.current && playing && sound.current.playing(),
    playerStatus,
    loading,
    setPlaying: setPlayingFunc,
    setAudioSeekTime
  }
}

export default useHowlAudioPlayer
