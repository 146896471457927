import { toNumber } from 'lodash'

const getTeacherViewEventDisplayTime = (
  viewEvents: {
    startTime: string | number | null | undefined
    elementId: string | null | undefined
    subjectId: string | null | undefined
    cardId: string | null | undefined
    templateId: string | null | undefined
  }[],
  findBy: {
    elementId: string
    subjectId: string
    cardId: string
    templateId: string
  }
) => {
  const viewIndex = viewEvents?.findIndex(it => {
    if (findBy.elementId || findBy.subjectId || findBy.cardId || findBy.templateId) {
      return (
        (findBy.elementId ? it?.elementId === findBy.elementId : true) &&
        (findBy.subjectId ? it?.subjectId === findBy.subjectId : true) &&
        (findBy.cardId ? it?.cardId === findBy.cardId : true) &&
        (findBy.templateId ? it?.templateId === findBy.templateId : true)
      )
    } else {
      return -1
    }
  })
  const currentView = viewEvents[viewIndex]
  const currentViewDisplayTime = toNumber(currentView?.startTime || 0)
  const nextView = currentView ? viewEvents[viewIndex + 1] : null
  const nextViewDisplayTime = toNumber(nextView?.startTime || Infinity)

  return { currentView, nextView, currentViewDisplayTime, nextViewDisplayTime }
}

export default getTeacherViewEventDisplayTime
