const roboTeacherTranscriptionConvertor = (text: any, language: string) => {
  // TODO: use enums for the language
  if (language === 'Persian' || language === 'Arabic') {
    const regex = /([A-Za-z][A-Za-z0-9\s,.?\/()!&'’‘-]*[A-Za-z0-9,.?\/()!&'’‘-])/g

    text = text.replace(regex, (match: string) => {
      return `<span dir="ltr">${match}</span>`
    })

    text = `<span dir="rtl">${text}</span>`
  }

  return text
}

export default roboTeacherTranscriptionConvertor
