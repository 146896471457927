import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'
import structureBaseImageMapper from './structureBaseImageMapper'

const structureSingleImageTemplateMapper = ({ item, events }) => {
  const { currentViewDisplayTime, nextViewDisplayTime } = getTeacherViewEventDisplayTime(events?.view, {
    templateId: item?.id
  })

  return {
    ...item,
    picture: structureBaseImageMapper({ image: item?.picture, text: null, events }),
    currentViewDisplayTime,
    nextViewDisplayTime
  }
}

export default structureSingleImageTemplateMapper
