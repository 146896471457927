import serviceCall from 'services'
import plansMapper from 'services/mapper/payment/plansMapper'
import farsiToEnglishNum from 'util/farsiToEnglishNum/farsiToEnglishNum'

const applyDiscountCodeUseCase = async ({ discountCode, asRenewal, purchaseType }) => {
  const result = await serviceCall(
    'fetchDiscounted',
    {
      discountCode: farsiToEnglishNum(discountCode),
      asRenewal
    },
    'cloud'
  )

  return plansMapper(result, purchaseType)
}

export default applyDiscountCodeUseCase
