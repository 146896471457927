import { useDispatch, useSelector } from 'react-redux'
import { setCurrentOpenModal } from 'redux/features/modalSlice'
import { globalModalEnums } from 'enums/globalEnums/globalEnums'
import AddToHomeScreenGuide from 'atomicComponents/templates/AddToHomeScreenGuide/AddToHomeScreenGuide'
import SubscriptionUpgradeModal from 'atomicComponents/organisms/SubscriptionUpgradeModal'

const GlobalModals = () => {
  const { currentOpenModal } = useSelector(state => state.modal)
  const dispatch = useDispatch()

  const renderModal = () => {
    if (currentOpenModal === globalModalEnums.addToHomeScreenGuide) {
      return <AddToHomeScreenGuide />
    } else if (currentOpenModal === globalModalEnums.subscriptionUpgrade) {
      return <SubscriptionUpgradeModal closeModal={() => dispatch(setCurrentOpenModal(null))} />
    } else {
      return null
    }
  }

  return <>{renderModal()}</>
}

export default GlobalModals
