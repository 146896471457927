import UnitDataStorage from 'classes/UnitDataStorage'
import { studyTypeEnum } from 'enums/userStudyEnums/userStudyEnums'
import studyTypeIsRepeat from 'operations/studyTypeIsRepeat'
import { FINISH_SECTION, SKIP_EXERCISE, START_EXERCISE, START_SECTION } from 'enums/studyLogEnums/studyLogEnums'
import { sectionActionType } from 'enums/sectionActionTypeEnums/sectionActionTypeEnums'
import { sendStudyLog } from 'redux/features/studyLogSlice'
import useEnhancedDispatch from 'hooks/useDispatchWrapper'

const useLog = () => {
  const { enhancedDispatch } = useEnhancedDispatch()

  const sendServerLog = async (type, data, then) => {
    const runThenFunc = res => {
      if (typeof then === 'function') {
        then(res)
      }
    }

    const unitDataStorage = new UnitDataStorage()
    const unitData = data ?? unitDataStorage.getUnitData()

    const post = action => {
      enhancedDispatch(sendStudyLog, {
        unitId: unitData?.unitNumber,
        sectionType: unitData?.sectionKey,
        type: unitData.studyStatus,
        action
      }).then(res => {
        runThenFunc(res)
      })
    }

    if (
      unitData &&
      (unitData.studyStatus === studyTypeEnum.NEW ||
        unitData.studyStatus === studyTypeEnum.REVIEW ||
        studyTypeIsRepeat(unitData.studyStatus))
    ) {
      switch (type) {
        case START_SECTION:
          console.info(START_SECTION, unitData)
          post(sectionActionType.start)
          break
        case FINISH_SECTION:
          console.info(FINISH_SECTION, unitData)
          post(sectionActionType.finish)
          break
        case START_EXERCISE:
          console.info(START_EXERCISE, unitData)
          post(sectionActionType.start)
          break
        case SKIP_EXERCISE:
          console.info(SKIP_EXERCISE, unitData)
          post(sectionActionType.skip)
          break
        default:
          runThenFunc()
      }
    } else {
      runThenFunc()
    }
  }

  return { sendServerLog }
}

export default useLog
