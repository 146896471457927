import React, { useContext, useEffect, useState } from 'react'
import ExerciseSection from '../Exercises/ExerciseSection'
import Header from '../../../components/Header/Header'
import FramerMotion from '../../../layouts/FramerMotion'
import colorsContext from '../../../contexts/ColorsContext'
import { getReviewMistakeExercise } from '../../../redux/features/reviewMistakeSlice'
import UnitDataStorage from '../../../classes/UnitDataStorage'
import exerciseTypeEnums from '../../../enums/exerciseEnums/exerciseEnums'
import reviewMistakeDragAndDropCategoryMapper from '../../../services/mapper/reviewMistake/reviewMistakeDragAndDropCategoryMapper'
import useEnhancedDispatch from '../../../hooks/useDispatchWrapper'

const ReviewExerciseMistakes = ({ data, onClose = () => {} }) => {
  const { colors } = useContext(colorsContext)
  const [exerciseData, setExerciseData] = useState(null)
  const { enhancedDispatch } = useEnhancedDispatch()

  useEffect(() => {
    const itemIds = data?.exerciseStacks?.[0]?.items?.map(it => it.id)

    const unitDataStorage = new UnitDataStorage()
    const unitData = unitDataStorage.getUnitData()

    if (data.type === exerciseTypeEnums.dragAndDropConversation) {
      enhancedDispatch(
        getReviewMistakeExercise, {
          unit: data.unitId,
          section: data.sectionType,
          sectionNumber: data.sectionNumber,
          groupType: unitData.exerciseGroupType,
          filterByIds: itemIds
        }
      ).then(res => {
        if (res.payload) {
          setExerciseData(res.payload)
        } else {
          setExerciseData(data)
        }
      })
    } else if (data.type === exerciseTypeEnums.dragAndDrop) {
      enhancedDispatch(
        getReviewMistakeExercise, {
          unit: data.unitId,
          section: data.sectionType,
          sectionNumber: data.sectionNumber,
          groupType: unitData.exerciseGroupType,
          filterByUserAnswerData: data.userAnswerItemResponse
        }
      ).then(res => {
        if (res.payload) {
          setExerciseData(res.payload)
        } else {
          setExerciseData(data)
        }
      })
    } else if (data.type === exerciseTypeEnums.dragAndDropCategory) {
      const result = reviewMistakeDragAndDropCategoryMapper(data, data.userAnswerItemResponse)
      setExerciseData(result)
    } else {
      setExerciseData(data)
    }

    return () => {
      onClose()
    }
  }, [])

  return (
    <FramerMotion className='minh-100vh pb-2' style={{ background: colors['C183'] }}>
      <Header
        withBackButton
        iconsColor={colors['C167']}
        title='Review Mistakes'
        backgroundColor={colors['C14']}
        titleColor={colors['C167']}
      />
      <div data-jsx='Section'>{exerciseData && <ExerciseSection sectionData={exerciseData} isReviewMistake />}</div>
    </FramerMotion>
  )
}

export default ReviewExerciseMistakes
