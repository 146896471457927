import React, { useContext, useEffect, useRef, useState } from 'react'
import HtmlTextWrapperStyle from './HtmlTextWrapper.module.scss'
import PhrasesOptions from '../PhrasesOptions/PhrasesOptions'
import getStyleObjectFromString from '../../operations/inlineCssToReactCss'
import removeWrapperTag from '../../operations/removeWrapperTag'
import reactStringReplace from 'react-string-replace'
import numberIsOdd from '../../operations/numberIsOdd'
import { isEmpty } from 'lodash'
import WordMoreModal from '../ThreeDot/WordMoreModal'
import Modal from '../Modal'
import structuresContext from '../../contexts/StructuresContext'
import classNames from 'classnames'
import { robotTeacherScrollIntoViewByElement } from '../../operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'
import WordLink from './WordLink'
import { useParams } from 'react-router-dom'

const HtmlTextWrapper = ({
  data,
  className,
  showPhraseOptions,
  moreOptionsList,
  textColor,
  optionsColor,
  style,
  phraseOptionsDisabled,
  highlightScrollInto,
  hideMore = false
}) => {
  const [sentence, setSentence] = useState('')
  const [showSentenceData, setShowSentenceData] = useState(false)
  const [paragraphGivenStyles, setParagraphGivenStyles] = useState({})
  const [moreModalPhrase, setMoreModalPhrase] = useState(null)
  const [openWordMoreModal, setOpenWordMoreModal] = useState(false)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { unit } = useParams()

  const isThisSentence =
    isRoboTeacher ?? false
      ? data?.startWordEventsTime <= roboTeacherData?.currentTime &&
        roboTeacherData?.currentTime <= data?.endWordEventsTime
      : false
  const currentWordEvent = isThisSentence
    ? data?.wordEvents?.filter(
        it => it?.startTime <= roboTeacherData?.currentTime && roboTeacherData?.currentTime <= it?.endTime
      )
    : null

  const sentenceContainerRef = useRef(null)

  useEffect(() => {
    if (currentWordEvent?.length > 0 && !scrolling) {
      robotTeacherScrollIntoViewByElement(
        highlightScrollInto ? highlightScrollInto?.current : sentenceContainerRef?.current
      )
    }
  }, [currentWordEvent?.length])

  useEffect(() => {
    // Clear given styles
    setParagraphGivenStyles({})

    if (data?.sentence && typeof data?.sentence === 'string') {
      let pStyle = ''

      const paragraphStyleRegex = /style=\"(.*?)\"/gi // style regex
      const paragraphRegex = /<p[^>]*>/g // <p> tag regex
      // Extract <p> tag from given data sentence
      const extractedParagraph = paragraphRegex.exec(data.sentence)
      if (extractedParagraph && extractedParagraph.length > 0) {
        // Extract style inline attribute from extracted paragraph
        const extractedStyle = paragraphStyleRegex.exec(extractedParagraph[0])
        if (extractedStyle && extractedStyle.length > 1) {
          pStyle = extractedStyle[1]
          /*
           * Convert inline style to jsx style object
           * The store it into paragraphGivenStyles state
           */
          setParagraphGivenStyles(getStyleObjectFromString(pStyle))
        }
      }

      // Remove paragraph from given data sentence
      // const result = data.sentence.replace(/<\/?p[^>]*>/g, '')
      const result = removeWrapperTag(data.sentence)

      setSentence(result)
      setShowSentenceData(false)
    } else {
      setShowSentenceData(true)
    }
  }, [data])

  const onClickAnchor = hrefValue => {
    setMoreModalPhrase(hrefValue)
    setOpenWordMoreModal(true)
  }

  const renderParagraphContent = () => {
    let count = 0
    const arr = reactStringReplace(sentence, /(<a\b[^>]*>(?:.*?)<\/a>)/g, (match, index) => {
      const fieldIndex = (index - 1) / 2 + count
      const highlighted = isRoboTeacher
        ? isThisSentence
          ? currentWordEvent?.some(it => it.indexes?.includes(fieldIndex.toString()))
          : false
        : false

      let content = ''
      const contentMatch = /<a[^>]*>(.*?)<\/a>/g.exec(match)
      if (contentMatch) {
        content = contentMatch[1]
      }

      let hrefValue = ''
      const hrefMatch = /href="([^\\"]*)"/g.exec(match)
      if (hrefMatch) {
        hrefValue = hrefMatch[1]
      }

      if (hrefValue && hrefValue !== '###' && hrefValue !== '#') {
        return (
          <WordLink highlighted={highlighted} content={content} hrefValue={hrefValue} />
          // <span
          //   className={classNames(HtmlTextWrapperStyle.word, {[HtmlTextWrapperStyle.highlighted]: highlighted})}
          //   onClick={() => onClickAnchor(hrefValue)}
          //   style={{cursor: 'pointer'}}
          //   dangerouslySetInnerHTML={{__html: content}}
          // />
        )
      } else {
        return (
          <span
            className={classNames(HtmlTextWrapperStyle.word, { [HtmlTextWrapperStyle.highlighted]: highlighted })}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )
      }
    })

    return arr.map((el, i) => (
      <React.Fragment key={i}>{numberIsOdd(i) ? el : <span dangerouslySetInnerHTML={{ __html: el }} />}</React.Fragment>
    ))
  }

  return (
    <>
      <div className={className} dir='auto' style={{ ...paragraphGivenStyles, ...style }}>
        {showPhraseOptions && (
          <span className={HtmlTextWrapperStyle.phraseOptionsWrapper}>
            <PhrasesOptions
              hideMore={hideMore}
              color={optionsColor}
              moreOptionsList={moreOptionsList}
              data={data}
              disabled={phraseOptionsDisabled}
              unitId={unit}
            />
          </span>
        )}
        <div
          data-rt-id={data?.id}
          className={HtmlTextWrapperStyle.textHolder}
          style={{ color: textColor, display: 'inline' }}
          ref={sentenceContainerRef}
        >
          {showSentenceData ? data?.sentence ?? null : renderParagraphContent()}
        </div>
      </div>
      {openWordMoreModal && (
        <Modal
          closeModal={() => {
            setOpenWordMoreModal(false)
            setMoreModalPhrase('')
          }}
          isCenter
        >
          <WordMoreModal phrase={moreModalPhrase} unitId={unit} />
        </Modal>
      )}
    </>
  )
}

export default HtmlTextWrapper
