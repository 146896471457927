import structureBaseTitleMapper from './structureBaseTitleMapper'
import { min } from 'lodash'
import { ContentTypesStructureEnums } from '../../../enums/structureEnums/templateType'
import structureBaseImageMapper from './structureBaseImageMapper'
import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'

const structureCompareMonolithicTemplateMapper = ({ item, events }) => {
  const { currentViewDisplayTime: currentTemplateViewDisplayTime, nextViewDisplayTime: nextTemplateViewDisplayTime } =
    getTeacherViewEventDisplayTime(events?.view, {
      templateId: item?.id
    })

  return {
    ...item,
    data: {
      cards: item.data.cards?.map(card => {
        const { currentViewDisplayTime: currentCardViewDisplayTime, nextViewDisplayTime: nextCardViewDisplayTime } =
          getTeacherViewEventDisplayTime(events?.view, {
            templateId: item?.id,
            cardId: card?.id
          })

        switch (card.type) {
          case ContentTypesStructureEnums.TITLE:
            return {
              ...card,
              data: {
                ...card.data,
                text: structureBaseTitleMapper({ title: card?.data?.text, events })
              },
              currentViewDisplayTime: currentCardViewDisplayTime,
              nextViewDisplayTime: nextCardViewDisplayTime
            }
          case ContentTypesStructureEnums.IMAGE:
            return {
              ...card,
              data: {
                ...card.data,
                pictureRight: structureBaseImageMapper({
                  image: card?.data?.pictureRight,
                  text: structureBaseTitleMapper({ text: card?.data?.pictureTextRight, events })
                }),
                pictureLeft: structureBaseImageMapper({
                  image: card?.data?.pictureLeft,
                  text: structureBaseTitleMapper({ text: card?.data?.pictureTextLeft, events })
                })
              },
              currentViewDisplayTime: currentCardViewDisplayTime,
              nextViewDisplayTime: nextCardViewDisplayTime
            }
          case ContentTypesStructureEnums.SUBJECT:
            return {
              ...card,
              data: {
                ...card.data,
                textsRight: card.data.textsRight.map(text => {
                  return {
                    ...text,
                    text: structureBaseTitleMapper({ title: text?.text, events })
                  }
                }),
                textsLeft: card.data.textsLeft.map(text => {
                  return {
                    ...text,
                    text: structureBaseTitleMapper({ title: text?.text, events })
                  }
                })
              },
              currentViewDisplayTime: currentCardViewDisplayTime,
              nextViewDisplayTime: nextCardViewDisplayTime
            }
        }
      })
    },
    currentViewDisplayTime: currentTemplateViewDisplayTime,
    nextViewDisplayTime: nextTemplateViewDisplayTime
  }
}

export default structureCompareMonolithicTemplateMapper
