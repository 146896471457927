import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import ChatBox from 'components/ChatBox/ChatBox'
import HintClueButton from 'components/HintClueButton/HintClueButton'
import { Link } from 'react-router-dom'
import HelpButton from 'components/HelpButton/HelpButton'
import { useDispatch, useSelector } from 'react-redux'
import PhrasesOptions from 'components/PhrasesOptions/PhrasesOptions'
import TitleBoxSvg from 'icons/TitleBoxSvg'
import {
  checkConversationMemorize,
  resetMemorizeDialog,
  toggleLock,
  setRecordModalIsOpen,
  setSpeechDialogNumber,
  setInputValue,
  setMemorizeCharacterSelected,
  setMemorizeSelectedCharacters,
  setPlaylist,
  unlockPlayingDialogs,
  setUserVoiceText,
  setConversationMemorizeSpeechType,
  getConversationMemorizeHint
} from 'redux/features/conversationSlice'
import Lock from 'components/Lock/Lock'
import OverlayScrollbar from 'layouts/OverlayScrollbar'
import Image from 'components/Image'
import htmlToText from 'operations/htmlToText'
import PillSmButton from 'components/PillSmButton/PillSmButton'
import ResetSvg from 'icons/ResetSvg'
import SpeechCircleButton from 'components/SpeechCircleButton/SpeechCircleButton'
import SpeechModal from 'components/SpeechModal/SpeechModal'
import { gsap } from 'gsap'
import ConversationCharacterSelect from 'layouts/ConversationCharacterSelect'
import { moreOptionsList } from 'data'
import HtmlTextWrapper from 'components/HtmlTextWrapper/HtmlTextWrapper'
import makeUrl from 'operations/makeUrl'
import { isEmpty } from 'lodash'
import PlayButtonPrimaryPlaylist from 'components/PlayButtonPrimary/PlayButtonPrimaryPlaylist'
import offsetMiddleParent from 'operations/offsetMiddleParent'
import getCachedMediaUrl from 'operations/getCachedMediaUrl'
import colorsContext from 'contexts/ColorsContext'
import PrimaryButton from 'components/PrimaryButton/PrimaryButton'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { pageGuidEnums } from 'enums/pageGuideEnums/pageGuideEnums'
import ExerciseMistakeInfoButton from 'components/ExerciseMistakeInfoButton/ExerciseMistakeInfoButton'
import useWakeLockApi from 'hooks/useWakeLockApi'
import speechRecognitionHistoryFactory from 'factory/speechRecognitionHistoryFactory'
import { pushSpeechRecognitionHistory } from 'redux/features/speechToTextSlice'
import cleanSentenceForCheck from 'operations/cleanSentenceForCheck'
import useEnhancedDispatch from 'hooks/useDispatchWrapper'
import useAppUser from 'hooks/useAppUser'
import SectionLockedIcon from 'atomicComponents/atoms/SectionLockedIcon/SectionLockedIcon'
import { setCurrentOpenModal } from 'redux/features/modalSlice'
import { globalModalEnums } from 'enums/globalEnums/globalEnums'

const Memorize = ({ unit, nextPage, getDialogBgColor }) => {
  const { colors } = useContext(colorsContext)
  const [focusedDialog, setFocusedDialog] = useState(null)
  const [playlistPlaying, setPlaylistPlaying] = useState(false)
  const [playLimit, setPlayLimit] = useState(-1)
  const [onVoiceDialogIndex, setOnVoiceDialogIndex] = useState(-1)

  const { speechRecognitionHistory, speechRecognitionType } = useSelector(state => state.speechToText)
  const states = useSelector(state => state.conversation)
  const dispatch = useDispatch()
  const { userHasPlan } = useAppUser()
  const { enhancedDispatch } = useEnhancedDispatch()

  const dialogsRef = useRef([])

  const userSelectedDialogs = useMemo(() => {
    return states.data.dialogData?.filter(it => states.memorizeSelectedCharacters.includes(it.characterName))
  }, [JSON.stringify(states.memorizeSelectedCharacters ?? [])])

  const allUserDialogsChecked = useMemo(() => {
    return userSelectedDialogs?.every(it => {
      return states.userAnswers?.find(userAnswer => userAnswer.dialogNumber === it.dialogNumber)?.checked
    })
  }, [JSON.stringify(userSelectedDialogs), states.userAnswers?.filter(it => it.checked)?.length ?? 0])

  const { requestWakeLock, releaseWakeLock } = useWakeLockApi()

  useEffect(() => {
    if (playlistPlaying) requestWakeLock()
    else releaseWakeLock()
  }, [playlistPlaying])

  useEffect(() => {
    if (states.voiceStr && states.speechDialogNumber != null) {
      dispatch(
        setInputValue({
          value: states.voiceStr,
          dialogNumber: states.speechDialogNumber,
          bySpeech: true
        })
      )
    }
  }, [states.voiceStr])

  useEffect(() => {
    let isMounted = true
    if (states.data && states.playlist.length === 0) {
      const createPlaylist = async () => {
        const arr = []
        for (let i = 0; i < states.data.dialogData.length; i++) {
          const r = await getCachedMediaUrl(makeUrl(states.data.dialogData[i].voiceUri))
          if (r) {
            arr.push(r)
          }
        }
        return arr
      }
      createPlaylist().then(res => {
        if (isMounted) {
          dispatch(setPlaylist(res))
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [states.data])

  useEffect(() => {
    // Set limit index for playlist
    if (!isEmpty(states.data)) {
      let limitIndex = -1
      states.data.dialogData.every(dialog => {
        if (states.memorizeSelectedCharacters.includes(dialog.characterName)) {
          const u = states.userAnswers.find(obj => obj.dialogNumber === dialog.dialogNumber)
          if (u && u.checked) {
            limitIndex++
            return true
          } else {
            return false
          }
        } else {
          limitIndex++
          return true
        }
      })
      setPlayLimit(limitIndex)
    }
  }, [states.memorizeSelectedCharacters, states.userAnswers, states.data])

  useEffect(() => {
    if (playlistPlaying) {
      // Unlock checked answers when playing playlist
      dispatch(unlockPlayingDialogs(playLimit))
    }
  }, [playlistPlaying])

  const getInputValue = dialogNumber => {
    const dialogAnswerObj = states.userAnswers.find(obj => obj.dialogNumber === dialogNumber)
    return dialogAnswerObj ? dialogAnswerObj.sentence : ''
  }

  const setUserAnswerValue = (value, dialogNumber) => {
    const u = states.userAnswers.find(obj => obj.dialogNumber === dialogNumber)
    dispatch(setInputValue({ value, dialogNumber, bySpeech: Boolean(u?.bySpeech) }))
  }

  const renderChatBox = (dialog, answerObj) => {
    if (states.memorizeSelectedCharacters.includes(dialog.characterName)) {
      return (
        <ChatBox
          bgColor={getDialogBgColor(dialog.characterName)}
          borderColor={
            answerObj && answerObj.checked
              ? answerObj.usedHint || !answerObj.isCorrect
                ? colors['C29']
                : colors['C30']
              : null
          }
        >
          <div className='dialog-wrapper'>
            {answerObj && answerObj.checked ? (
              <div className='font-size-30' style={{ color: colors['C23'] }}>
                {answerObj.unlocked ? (
                  <HtmlTextWrapper
                    data={dialog}
                    textColor={colors['C23']}
                    optionsColor={colors['C23']}
                    className='c-inherit-important'
                    style={{ color: colors['C30'] }}
                  />
                ) : (
                  <>
                    {!answerObj.isCorrect ? (
                      <span style={{ color: colors['C29'] }}>{answerObj.sentence}</span>
                    ) : (
                      <HtmlTextWrapper
                        data={dialog}
                        textColor={colors['C30']}
                        optionsColor={colors['C30']}
                        className='d-inline-block'
                      />
                    )}
                    {answerObj.isCorrectWithMistake && (
                      <span style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 6 }}>
                        <ExerciseMistakeInfoButton wordByWordResult={answerObj.checkResult} />
                      </span>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className='dialog-textarea-cover'>
                <HtmlTextWrapper className='font-size-30' data={dialog} />
              </div>
            )}
            <textarea
              onKeyPress={e => {
                if (e.key === 'Enter' || e.key === 'ctrlKey') {
                  e.preventDefault()
                  checkDialogAnswer(dialog.dialogNumber, true)
                }
              }}
              className='dialog-textarea font-size-30'
              style={{
                backgroundImage: `url('${window.location.origin}/assets/images/input_dots.svg')`,
                color: colors['C23'],
                display: answerObj && answerObj.checked ? 'none' : null
              }}
              disabled={states.typeMethod !== 'keyboard'}
              value={getInputValue(dialog.dialogNumber)}
              onChange={e => setUserAnswerValue(e.target.value, dialog.dialogNumber)}
              onFocus={() => {
                // Put this dialog answer inside userAnswers list
                setUserAnswerValue(getInputValue(dialog.dialogNumber), dialog.dialogNumber)
                // Set focused dialog number
                setFocusedDialog(dialog.dialogNumber)
              }}
            />
          </div>
        </ChatBox>
      )
    } else {
      return (
        <ChatBox bgColor={getDialogBgColor(dialog.characterName)}>
          <HtmlTextWrapper
            className='font-size-30'
            data={dialog}
            textColor={colors['C23']}
            optionsColor={colors['C23']}
          />
        </ChatBox>
      )
    }
  }

  const renderPhrasesOptions = (dialog, dialogIndex) => {
    return (
      <div className='tools'>
        <PhrasesOptions
          handlePlaying={playing => {
            if (playing) {
              setOnVoiceDialogIndex(dialogIndex)
            } else if (onVoiceDialogIndex === dialogIndex) {
              setOnVoiceDialogIndex(-1)
            }
          }}
          moreOptionsList={moreOptionsList}
          data={dialog}
          unitId={unit}
          color={colors['C23']}
        />
      </div>
    )
  }

  const renderDialogRightItems = (dialog, dialogIndex) => {
    const answerObj = states.userAnswers.find(obj => obj.dialogNumber === dialog.dialogNumber)
    if (states.memorizeSelectedCharacters.includes(dialog.characterName)) {
      if (answerObj && answerObj.checked) {
        if (!answerObj.isCorrect) {
          // If user answer is wrong
          return (
            <div className='d-flex flex-column align-items-center'>
              <Lock
                onClickLock={() => {
                  dispatch(
                    toggleLock({
                      value: !answerObj.unlocked,
                      dialogNumber: dialog.dialogNumber
                    })
                  )
                }}
                isLocked={!answerObj.unlocked}
              />
              {answerObj.unlocked && <div className='mt-2'>{renderPhrasesOptions(dialog, dialogIndex)}</div>}
            </div>
          )
        } else {
          return renderPhrasesOptions(dialog, dialogIndex)
        }
      } else {
        return (
          <div className='d-flex flex-column align-items-center'>
            <div className='mb-2'>
              <SpeechCircleButton
                bgColor='transparent'
                iconColor={colors['C213']}
                borderColor={colors['C213']}
                isSmall
                onClick={() => {
                  dispatch(setSpeechDialogNumber(dialog.dialogNumber))
                  dispatch(setRecordModalIsOpen(true))
                }}
              />
            </div>
            {answerObj && answerObj.sentence !== '' && (
              <PillSmButton
                disabled={cleanSentenceForCheck(answerObj.sentence) === ''}
                onClick={() => checkDialogAnswer(dialog.dialogNumber, true)}
                color={colors['C225']}
              >
                check
              </PillSmButton>
            )}
          </div>
        )
      }
    } else {
      return renderPhrasesOptions(dialog, dialogIndex)
    }
  }

  const resetDialogAnswer = (dialogNumber, i) => {
    dispatch(
      resetMemorizeDialog({
        dialogNumber: dialogNumber
      })
    )
    setFocusedDialog(dialogNumber)
  }

  const renderDialogs = () => {
    return states.data.dialogData.map((dialog, i) => {
      const answerObj = states.userAnswers.find(obj => obj.dialogNumber === dialog.dialogNumber)

      return (
        <div
          className={`single-convs-wrapper mb-3 ${onVoiceDialogIndex === i ? 'dialog-on-voice' : ''}`}
          ref={el => (dialogsRef.current[i] = el)}
          key={i}
        >
          <div className='d-flex w-100'>
            <div className='profile'>
              <div
                className='avatar'
                style={{
                  borderColor:
                    onVoiceDialogIndex === i
                      ? states.memorizeSelectedCharacters.includes(dialog.characterName)
                        ? colors['C224']
                        : colors['C221']
                      : 'transparent'
                }}
              >
                {states.memorizeSelectedCharacters.includes(dialog.characterName) && answerObj && answerObj.checked && (
                  <button onClick={() => resetDialogAnswer(dialog.dialogNumber, i)} className='reset-cover'>
                    <ResetSvg color={colors['C160']} />
                  </button>
                )}
                <Image uri={dialog.pictureUri} />
              </div>
              <span
                className='username font-size-17'
                style={{
                  color: states.memorizeSelectedCharacters.includes(dialog.characterName)
                    ? colors['C224']
                    : colors['C221']
                }}
              >
                {dialog.characterName}
              </span>
            </div>
            {renderChatBox(dialog, answerObj)}
          </div>
          <div className='dialog-right-items'>{renderDialogRightItems(dialog, i)}</div>
        </div>
      )
    })
  }

  const onSelectCharacter = name => {
    dispatch(setMemorizeSelectedCharacters(name))
  }

  const checkDialogAnswer = dialogNumber => {
    const dialogAnswerObj = states.userAnswers.find(obj => obj.dialogNumber === dialogNumber)

    if (dialogAnswerObj && cleanSentenceForCheck(dialogAnswerObj.sentence) !== '' && !states.isChecking) {
      const dialogIndex = states.data.dialogData.findIndex(obj => obj.dialogNumber === dialogNumber)
      const dialog = states.data.dialogData[dialogIndex]
      const dialogSentence = htmlToText(dialog.sentence)

      const matchCases = {
        [dialogSentence]: dialogAnswerObj?.sentence
      }

      enhancedDispatch(checkConversationMemorize, {
        unit: unit,
        matchCases,
        dialogNumber,
        trackerNumber: dialog.trackerNumber,
        isVoiceRecognition: dialogAnswerObj.bySpeech
      }).then(res => {
        if (dialogAnswerObj.bySpeech) {
          let isCorrect = res.payload?.data?.matchResultDtos?.[0]?.wordByWordResult?.every(it => it.match)

          const currentSpeechType = dialogAnswerObj.speechType ?? speechRecognitionType
          const speechData = {
            input: dialogAnswerObj.sentence,
            given: dialogSentence,
            practiceType: 'CONVERSATION_MEMORIZE',
            speechType: currentSpeechType,
            isCorrect
          }
          const { newHistory, newSpeechType } = speechRecognitionHistoryFactory(
            currentSpeechType,
            speechRecognitionHistory,
            speechData
          )
          dispatch(pushSpeechRecognitionHistory(newHistory))
          dispatch(setConversationMemorizeSpeechType({ dialogIndex, speechType: newSpeechType }))
        }

        const cardInner = document.querySelector('.convs-card-inner')
        let dialogsScrollContainer
        if (isMobile) {
          dialogsScrollContainer = cardInner
        } else {
          dialogsScrollContainer = cardInner.parentNode
        }

        states.data.dialogData
          .filter((obj, i) => i > dialogIndex)
          .every((obj, i) => {
            const item = dialogsRef.current[i + 1 + dialogIndex]

            if (states.memorizeSelectedCharacters.includes(obj.characterName)) {
              gsap.to(dialogsScrollContainer, {
                scrollTop: offsetMiddleParent(item),
                duration: 0.5
              })
              setTimeout(() => {
                dialogsRef.current[i + 1 + dialogIndex].querySelector('textarea').focus()
              }, 500)
              return false
            }
            return true
          })
      })
    }
  }

  const onRecordFinished = text => {
    dispatch(setUserVoiceText({ text }))
  }

  const dialogAnswerObj = useMemo(
    () => states.userAnswers.find(obj => obj.dialogNumber === focusedDialog),
    [states.userAnswers]
  )

  /*
   * This hint will add a correct word inside textarea
   * Hint placement starts from the first word
   */
  const onClickHint = () => {
    if (dialogAnswerObj && !states.isChecking && !dialogAnswerObj.checked) {
      const dialogIndex = states.data.dialogData.findIndex(obj => obj.dialogNumber === focusedDialog)
      const dialog = states.data.dialogData[dialogIndex]
      const dialogSentence = htmlToText(dialog.sentence)

      const matchCases = {
        [dialogSentence]: dialogAnswerObj?.sentence
      }
      enhancedDispatch(getConversationMemorizeHint, {
        unit: unit,
        matchCases,
        dialogNumber: focusedDialog,
        trackerNumber: dialog.trackerNumber
      })
    }
  }

  // const thereIsAnUncheckedAnswer =
  //   states.userAnswers.some(obj => (
  //     obj.sentence !== '' && !obj.checked
  //   ))

  if (!states.memorizeCharacterSelected) {
    return (
      <ConversationCharacterSelect
        helpCharacterSelectLink={pageGuidEnums.MEMORIZE_BEFORE_SELECTION}
        characters={states.data.characters}
        onClickDone={() => dispatch(setMemorizeCharacterSelected(true))}
        selectText='Select one or more characters.'
        title={'Memorize'}
        selectedCharacters={states.memorizeSelectedCharacters}
        onSelectCharacter={name => onSelectCharacter(name)}
      />
    )
  } else {
    return (
      <>
        <div data-jsx='Conversation'>
          <div className='container-fluid'>
            <div className='d-flex justify-content-end mt-3'>
              <div className='d-flex align-items-center me-3 me-md-5 ms-2'>
                <HelpButton color={colors['C2']} pageType={pageGuidEnums.CONVERSATION_MEMORIZE} />
              </div>
            </div>
            <div className='conversation-wrapper'>
              <section className='first-section mt-2 mt-lg-4'>
                <div className='first-section-inner'>
                  <div style={{ maxWidth: '100%' }}>
                    <div className='convs-img-container'>
                      <Image uri={states.data.pictureUri} className='convs-img' onClickZoomIn />
                    </div>
                    <div className='buttons-control justify-content-between'>
                      <div className='characters-wrapper'>
                        {states.data.characters.map((c, i) => {
                          return (
                            <div
                              className={`character-container ${
                                states.memorizeSelectedCharacters.includes(c.name) ? 'active' : ''
                              }`}
                              onClick={() => onSelectCharacter(c.name)}
                              key={i}
                            >
                              <div className='avatar' style={{ borderColor: colors['C66'], background: '#ccc' }}>
                                <Image uri={c.pictureUri} className='convs-img' />
                              </div>
                              <div className='character-name' style={{ color: colors['C578'] }}>
                                {c.name}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <div className='hint-button-container'>
                        {states.playlist.length > 0 && (
                          <PlayButtonPrimaryPlaylist
                            iconColor={colors['C160']}
                            bgColor={colors['C60']}
                            borderColor={colors['C60']}
                            playing={playlistPlaying}
                            handlePlaying={setPlaylistPlaying}
                            playLimit={playLimit}
                            playlistUrls={states.playlist}
                            setCurrentIndex={setOnVoiceDialogIndex}
                          />
                        )}
                        <HintClueButton
                          onClickHint={onClickHint}
                          disabled={focusedDialog == null || dialogAnswerObj?.checked}
                          loading={states.isChecking}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className='second-section'>
                <div className='convs-card-container'>
                  <div className='convs-card hidden-mobile' style={{ background: colors['C62'] }}>
                    <div className='title-box'>
                      <TitleBoxSvg />
                      <HtmlTextWrapper className='title-value' textColor={colors['C160']} data={states.data.title} />
                    </div>
                    <OverlayScrollbar>
                      <div className={classNames('convs-card-inner', { ['isMobile']: isMobile })}>
                        {renderDialogs()}
                      </div>
                    </OverlayScrollbar>
                  </div>
                </div>
                <div className='button-group'>
                  {userHasPlan ? (
                    <Link
                      to='?archive=true'
                      className='link-style archive-button font-weight-600'
                      style={{ color: colors['C2'] }}
                    >
                      Archive
                    </Link>
                  ) : (
                    <button
                      className='link-style archive-button font-weight-600 tw-relative'
                      style={{ color: colors['C2'] }}
                      onClick={() => dispatch(setCurrentOpenModal(globalModalEnums.subscriptionUpgrade))}
                    >
                      <div className='tw-absolute tw-right-[calc(100%_+_4px)] tw-top-1/2 -tw-translate-y-1/2'>
                        <SectionLockedIcon nonButton />
                      </div>
                      Archive
                    </button>
                  )}
                  <div className='d-flex justify-content-center'>
                    <PrimaryButton onClick={nextPage} disabled={!allUserDialogsChecked}>
                      Next
                    </PrimaryButton>
                  </div>
                  <button
                    onClick={nextPage}
                    disabled={allUserDialogsChecked}
                    className={classNames('link-style skip-button font-weight-600', {
                      ['disabled']: allUserDialogsChecked
                    })}
                    style={{ color: colors['C2'] }}
                  >
                    Skip
                  </button>
                </div>
              </section>
            </div>
          </div>
          {states.recordModalIsOpen && (
            <SpeechModal
              speechType={states.data.dialogData[states.speechDialogNumber - 1]?.speechType}
              given={states.data.dialogData[states.speechDialogNumber - 1]?.sentenceString}
              onRecordFinished={file => onRecordFinished(file)}
              loading={states.spellPending}
            />
          )}
        </div>
      </>
    )
  }
}

export default Memorize
