import { useNavigate, useSearchParams } from 'react-router-dom'
import Modal from 'components/Modal'
import ThreeDotModal from 'components/ThreeDot/ThreeDotModal'
import WordMoreModal from 'components/ThreeDot/WordMoreModal'
import { isEmpty } from 'lodash'
import useQueryParams from 'hooks/useQueryParams'

const PhraseOptionsModal = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const openPhraseOptions = searchParams.get('openPhraseOptions')
  const phraseType = searchParams.get('phraseType')
  const phraseUnit = searchParams.get('phraseUnit')
  const phraseOptions = searchParams.getAll('phraseOptions')
  const { removeQueryParams } = useQueryParams()

  const close = () => {
    navigate(-1)
    setTimeout(() => {
      removeQueryParams(['phraseOptions', 'openPhraseOptions', 'phraseType', 'phraseUnit'])
    }, 10)
  }

  return (
    <>
      {openPhraseOptions &&
        (phraseType === 'dictionary' ? (
          <Modal isCenter closeModal={close}>
            <WordMoreModal
              unitId={phraseUnit}
              optionsList={!isEmpty(phraseOptions) ? phraseOptions : undefined}
              phrase={openPhraseOptions}
            />
          </Modal>
        ) : (
          <Modal isCenter closeModal={close}>
            <ThreeDotModal
              unitId={phraseUnit}
              optionsList={!isEmpty(phraseOptions) ? phraseOptions : undefined}
              data={{ trackerNumber: openPhraseOptions }}
            />
          </Modal>
        ))}
    </>
  )
}

export default PhraseOptionsModal
