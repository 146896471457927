import React, { useContext, useEffect } from 'react'
import colorsContext from '../../../contexts/ColorsContext'
import exerciseStyles from './../ExerciseModules.module.scss'
import classNames from 'classnames'
import {
  checkExerciseItem,
  goToNextItem,
  selectOptionMC,
  setExerciseItemUpdated
} from '../../../redux/features/exerciseSlice'
import { useDispatch, useSelector } from 'react-redux'
import GivenTemplate from '../../../components/GivenTemplate/GivenTemplate'
import PaginableExerciseModule from '../PaginableExerciseModule'
import getUserStatusColor from '../../../operations/getUserStatusColor'
import TipCard from '../../../components/TipCard/TipCard'
import HtmlTextWrapper from '../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../../data'
import ExerciseItemCheck from '../../../components/ExerciseItemCheck/ExerciseItemCheck'
import getUserAnswerStatusBorderColor from '../../../operations/getUserAnswerStatusBorderColor'
import ExerciseGivenTextWithField from './Components/ExerciseGivenTextWithField'
import ExerciseAIAssistantButton from '../../../components/ExerciseAIAssistantButton/ExerciseAIAssistantButton'
import { AnswerStatusEnums, ONE_COLUMN, TWO_COLUMN } from '../../../enums/globalEnums/globalEnums'
import useEnhancedDispatch from '../../../hooks/useDispatchWrapper'

const MultipleChoiceModule = () => {
  const { colors } = useContext(colorsContext)
  const dispatch = useDispatch()
  const { enhancedDispatch } = useEnhancedDispatch()
  const { data, isSingleTemplate, currentItem, checkingItem, exerciseItemUpdated } = useSelector(
    state => state.exercise
  )

  useEffect(() => {
    if (exerciseItemUpdated) {
      if (data.immediateCheck) {
        const currentEnableStack = data.stacks[currentItem.stackIndex]
        const currentEnableItem = currentEnableStack.items[currentItem.itemIndex]

        const isAbleToCheck = currentEnableItem.choices.some(it => it.selected)

        if (isAbleToCheck) {
          onCheckItem(currentEnableStack.id, currentEnableItem)
        }
      }
      dispatch(setExerciseItemUpdated(false))
    }
  }, [exerciseItemUpdated])

  const renderGivenText = exerciseItem => {
    return (
      <ExerciseGivenTextWithField
        givenText={exerciseItem.given.text}
        fullTexts={exerciseItem.extra.fullTextAnswers}
        checked={exerciseItem.checked}
        unlocked={exerciseItem.unlocked}
        userAnswerStatus={exerciseItem.checked ? AnswerStatusEnums.CORRECT : null}
      />
    )
  }

  const onCheckItem = (stackId, exerciseItem) => {
    const selectedChoice = exerciseItem.choices.find(item => item.selected)
    const userAnswerData = {
      exerciseStackId: stackId,
      userAnswerItems: [
        {
          exerciseItemId: exerciseItem.id,
          userAnswers: [
            {
              listIndex: 0,
              index: selectedChoice ? selectedChoice.index : null,
              skipped: !selectedChoice
            }
          ]
        }
      ]
    }
    enhancedDispatch(checkExerciseItem, { userAnswerData }).then(res => {
      if (res.payload?.status === 200 && !isSingleTemplate) {
        dispatch(goToNextItem())
      }
    })
  }

  const defaultOptionStyles = {
    background: colors['C537'],
    color: colors['C160']
  }
  const selectedOptionStyles = {
    background: 'transparent',
    color: colors['C530'],
    borderColor: colors['C530']
  }

  const renderOption = (exerciseItem, stackIndex, itemIndex, choice, choiceIndex, enabled) => {
    if (exerciseItem.checked) {
      return (
        <button
          className={classNames(exerciseStyles.choiceOption, {
            [exerciseStyles.choiceOptionSmall]: choice.template === TWO_COLUMN,
            [exerciseStyles.choiceOptionLarge]: choice.template === ONE_COLUMN
          })}
          disabled={exerciseItem.checked}
          style={{
            background: choice.selected
              ? colors[getUserStatusColor(exerciseItem.userAnswer.userAnswerStatus)]
              : choice.isAnswer
              ? colors['C30']
              : colors['C537'],
            color: colors['C160']
          }}
        >
          {exerciseItem.checked ? (
            <HtmlTextWrapper
              data={choice.text}
              textColor={colors['C160']}
              // hideMore
              // optionsColor={colors['C160']}
              // showPhraseOptions
              // moreOptionsList={moreOptionsList}
            />
          ) : (
            choice.text.sentenceString
          )}
        </button>
      )
    }
    return (
      <button
        className={classNames(exerciseStyles.choiceOption, {
          [exerciseStyles.choiceOptionSmall]: choice.template === TWO_COLUMN,
          [exerciseStyles.choiceOptionLarge]: choice.template === ONE_COLUMN,
          [exerciseStyles.selected]: choice.selected
        })}
        onClick={() => {
          if (enabled && !checkingItem) {
            dispatch(selectOptionMC({ stackIndex, itemIndex, choiceIndex }))
          }
        }}
        disabled={checkingItem || !enabled}
        style={choice.selected ? selectedOptionStyles : defaultOptionStyles}
      >
        {choice.text.sentenceString}
      </button>
    )
  }

  const renderExerciseItem = (exerciseItem, exerciseItemClass, stackIndex, itemIndex, enabled) => {
    return (
      <div
        className={classNames(exerciseItemClass, exerciseStyles.multipleChoiceCard, {
          [exerciseStyles.cardDisabled]: !enabled && !exerciseItem.checked,
          [exerciseStyles.checked]: exerciseItem.checked
        })}
        style={{
          background: colors['C527'],
          borderColor: exerciseItem.checked
            ? colors[getUserAnswerStatusBorderColor(exerciseItem.userAnswer.userAnswerStatus)]
            : colors['C160']
        }}
        key={itemIndex}
      >
        <div className='d-flex'>
          <div
            className={exerciseStyles.cardNumberBadge}
            style={{
              background: colors['C144'],
              color: colors['233'],
              borderColor: exerciseItem.checked
                ? exerciseItem.userAnswer.userAnswerStatus === AnswerStatusEnums.CORRECT
                  ? colors['C30']
                  : colors['C29']
                : null
            }}
          >
            {itemIndex + 1}
          </div>
          <GivenTemplate
            hideVoice={exerciseItem.checked}
            exerciseItem={exerciseItem}
            givenText={renderGivenText(exerciseItem)}
          />
          <div className={exerciseStyles.cardNumberBadge} />
        </div>
        <div className={classNames(exerciseStyles.choiceOptionsContainer, 'row')}>
          {exerciseItem.choices.map((choice, choiceIndex) => (
            <div
              className={classNames(exerciseStyles.choiceOptionWrapper, {
                'col-6': choice.template === TWO_COLUMN,
                'col-12': choice.template === ONE_COLUMN
              })}
              key={choiceIndex}
            >
              {renderOption(exerciseItem, stackIndex, itemIndex, choice, choiceIndex, enabled)}
            </div>
          ))}
        </div>
        {exerciseItem.checked && exerciseItem.extra.tip && (
          <div className='mt-2'>
            <TipCard>
              <HtmlTextWrapper
                data={exerciseItem.extra.tip}
                textColor={colors['C233']}
                showPhraseOptions
                moreOptionsList={['BOX', 'FAVORITES', 'TRANSLATE']}
              />
            </TipCard>
          </div>
        )}
        {/*{(exerciseItem.checked && exerciseItem.userAnswer.userAnswerStatus === 'INCORRECT') &&*/}
        {/*  <div className={classNames(exerciseStyles.exerciseCardFooter)}>*/}
        {/*    <ExerciseAIAssistantButton exerciseItemId={exerciseItem.id}/>*/}
        {/*  </div>*/}
        {/*}*/}
        {!exerciseItem.checked && !data?.immediateCheck && (
          <div className={classNames(exerciseStyles.exerciseCardFooter, 'justify-content-center')}>
            <ExerciseItemCheck
              disabled={!enabled || !exerciseItem.choices.find(item => item.selected)}
              checking={enabled && checkingItem}
              onClick={() => {
                if (enabled && !checkingItem)
                  onCheckItem(data.stacks[stackIndex].id, exerciseItem, stackIndex, itemIndex)
              }}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={exerciseStyles.multipleChoiceContainer}>
      <PaginableExerciseModule renderExerciseItem={renderExerciseItem} />
    </div>
  )
}

export default MultipleChoiceModule
