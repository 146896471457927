import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { fetchStructuredSection } from '../../redux/features/structuredSectionSlice'
import StructureModule from '../../modules/StructuredSectionModules/StructureModule'
import { START_SECTION } from '../../enums/studyLogEnums/studyLogEnums'
import useLog from '../../hooks/logHooks/useLog'
import { pageGuidEnums } from '../../enums/pageGuideEnums/pageGuideEnums'
import useEnhancedDispatch from '../../hooks/useDispatchWrapper'

const PointPlus = ({ sectionName }) => {
  const { unit } = useParams()

  const states = useSelector(state => state.structuredSection)

  const { enhancedDispatch } = useEnhancedDispatch()
  const { sendServerLog } = useLog()

  const fetchData = () => {
    enhancedDispatch(fetchStructuredSection, { unit, sectionType: sectionName }).then(() => {
      sendServerLog(START_SECTION)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <StructureModule pageType={pageGuidEnums.POINT_PLUS_SECTION} structuresData={states.data} fetchData={fetchData} />
  )
}

export default PointPlus
