import structureBaseTitleMapper from './structureBaseTitleMapper'
import structureBaseImageMapper from './structureBaseImageMapper'
import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'

const structureTableTemplateMapper = ({ item, events }) => {
  const { currentViewDisplayTime, nextViewDisplayTime } = getTeacherViewEventDisplayTime(events?.view, {
    templateId: item?.id
  })

  return {
    ...item,
    data: {
      ...item.data,
      cells: item.data?.cells?.map(cell => {
        return {
          ...cell,
          data: {
            text: structureBaseTitleMapper({ title: cell?.data?.text, events }),
            picture: structureBaseImageMapper({ image: cell?.data?.picture, text: null, events })
          }
        }
      })
    },
    currentViewDisplayTime,
    nextViewDisplayTime
  }
}

export default structureTableTemplateMapper
