import { serviceAccessTypes } from '../enums/axiosEnums'
import publicAxiosInstance from './publicAxiosInstance'
import userAxiosInstance from './userAxiosInstance'
import studentAxiosInstance from './studentAxiosInstance'
import waitForTime from 'util/waitForTime'

// Assuming you have a context or a global store
let isRetryInProgress = false
let retryAgain = false

export const retryFailedRequests = async () => {
  if (isRetryInProgress) {
    retryAgain = true
    return
  }

  isRetryInProgress = true

  let failedRequests = JSON.parse(localStorage.getItem('failedRequests')) || []

  for (let i = 0; i < failedRequests.length; i++) {
    const req = failedRequests[i]
    let { accessType, config, retryCount, maxRetries, retryDelay, done } = req

    maxRetries = Math.min(maxRetries, 10)
    retryDelay = Math.max(retryDelay, 10000)

    if (!done) {
      for (let j = retryCount; j < maxRetries; j++) {
        await waitForTime(retryDelay)

        try {
          switch (accessType) {
            case serviceAccessTypes.STUDENT:
              await studentAxiosInstance(config)
              break
            case serviceAccessTypes.USER:
              await userAxiosInstance(config)
              break
            case serviceAccessTypes.PUBLIC:
              await publicAxiosInstance(config)
              break
          }

          failedRequests[i] = { ...req, done: true }
        } catch (err) {
          if (err?.response?.status === 500) {
            failedRequests[i] = { ...failedRequests[i], retryCount: failedRequests[i]?.retryCount + 1 }
          } else {
            failedRequests[i] = { ...failedRequests[i], done: true }
          }
        }

        let newFailedRequests = JSON.parse(localStorage.getItem('failedRequests')) || []

        const currentReqIndex = newFailedRequests.findIndex(it => {
          return JSON.stringify(it?.config) === JSON.stringify(req?.config)
        })

        if (currentReqIndex !== -1) {
          newFailedRequests[currentReqIndex] = failedRequests[i]
          localStorage.setItem('failedRequests', JSON.stringify(newFailedRequests))
        }
      }
    }
  }

  const newFailedRequests = JSON.parse(localStorage.getItem('failedRequests')) || []
  const remainingRequests = newFailedRequests.filter(it => !it?.done && it.retryCount < it.maxRetries)

  if (remainingRequests.length > 0) {
    localStorage.setItem('failedRequests', JSON.stringify(remainingRequests))
  } else {
    localStorage.removeItem('failedRequests')
  }

  isRetryInProgress = false

  if (retryAgain) {
    retryAgain = false
    retryFailedRequests()
  }
}
