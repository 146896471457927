import React, { useContext, useEffect } from 'react'
import HelpButton from '../../../components/HelpButton/HelpButton'
import styles from '../../Games/games.module.scss'
import { Link, useParams } from 'react-router-dom'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import colorsContext from '../../../contexts/ColorsContext'
import { START_SECTION } from '../../../enums/studyLogEnums/studyLogEnums'
import useLog from '../../../hooks/logHooks/useLog'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'
import useSectionEvents from '../../../hooks/sectionHooks/useSectionEvents'
import SkipButton from '../../../components/SkipButton/SkipButton'
import { useSelector } from 'react-redux'

const GamesSectionPage = () => {
  const { unit } = useParams()
  const { colors } = useContext(colorsContext)
  const { sendServerLog } = useLog()
  const { navigateToNextSection } = useSectionEvents()
  const { logPending } = useSelector(state => state.studyLog)

  useEffect(() => {
    sendServerLog(START_SECTION)
  }, [])

  return (
    <div className='pt-3'>
      <div className='container-fluid'>
        <div className='d-flex justify-content-end'>
          <div className='d-flex align-items-center me-3 me-md-5 ms-2'>
            <HelpButton color={colors['C2']} pageType={pageGuidEnums.GAME_SECTION} />
          </div>
        </div>
      </div>
      <div className='container mt-4 mb-3'>
        <div className={styles.gameCards}>
          <div className={styles.gameCardContainer}>
            <Link
              to={`/games/blink/unit/${unit}?origin=section`}
              className={styles.gameCard}
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url('${window.location.origin}/assets/images/games/blink-o.png')`
              }}
            >
              <div className={styles.iconCircle} style={{ background: '#fff' }}>
                <img src={window.location.origin + '/assets/images/games/blink_icon.png'} alt='' />
              </div>
              <div className={styles.cardContent}>
                <p className={styles.cardTitle} style={{ color: colors['C233'] }}>
                  Blink
                </p>
                <div className={styles.cardDesc}>
                  <p style={{ color: colors['C233'] }}>
                    Language skills: Vocabulary knowledge
                    <br />
                    Cognitive benefits: Improving visual & processing skills
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className={styles.gameCardContainer}>
            <Link
              to={`/games/match-up/unit/${unit}?origin=section`}
              className={styles.gameCard}
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url('${window.location.origin}/assets/images/games/match-up-o.png')`
              }}
            >
              <div className={styles.iconCircle} style={{ background: '#fff' }}>
                <img src={window.location.origin + '/assets/images/games/match_up_icon.png'} alt='' />
              </div>
              <div className={styles.cardContent}>
                <p className={styles.cardTitle}>Match-up</p>
                <div className={styles.cardDesc}>
                  <p>
                    Language skills: Vocabulary knowledge
                    <br />
                    Cognitive benefits: Improving concentration skills
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className={styles.gameCardContainer}>
            <Link
              to={`/games/limbic-power/unit/${unit}?origin=section`}
              className={styles.gameCard}
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url('${window.location.origin}/assets/images/games/limbic-power-o.png')`
              }}
            >
              <div className={styles.iconCircle} style={{ background: '#fff' }}>
                <img src={window.location.origin + '/assets/images/games/limbic_power_icon.png'} alt='' />
              </div>
              <div className={styles.cardContent}>
                <p className={styles.cardTitle}>Limbic Power</p>
                <div className={styles.cardDesc}>
                  <p>
                    Language skills: Vocabulary knowledge
                    <br />
                    Cognitive benefits: Improving short term memory
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className={styles.gameCardContainer}>
            <Link
              to={`/games/phonometrix/unit/${unit}?origin=section`}
              className={styles.gameCard}
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url('${window.location.origin}/assets/images/games/phono-metrix-o.png')`
              }}
            >
              <div className={styles.iconCircle} style={{ background: '#fff' }}>
                <img src={window.location.origin + '/assets/images/games/phono_metrix_icon.png'} alt='' />
              </div>
              <div className={styles.cardContent}>
                <p className={styles.cardTitle}>PhonoMetrix</p>
                <div className={styles.cardDesc}>
                  <p>
                    Language skills: Spelling & Literacy
                    <br />
                    Cognitive benefits: Improving overall memory skills
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className={styles.gameCardContainer}>
            <Link
              to={`/games/phonotype/unit/${unit}?origin=section`}
              className={styles.gameCard}
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url('${window.location.origin}/assets/images/games/phono-type-o.png')`
              }}
            >
              <div className={styles.iconCircle} style={{ background: '#fff' }}>
                <img src={window.location.origin + '/assets/images/games/phono_type_icon.png'} alt='' />
              </div>
              <div className={styles.cardContent}>
                <p className={styles.cardTitle}>PhonoType</p>
                <div className={styles.cardDesc}>
                  <p>
                    Language skills: Listening & Spelling
                    <br />
                    Cognitive benefits: Improving pattern recognition
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className={styles.gameCardContainer}>
            <Link
              to={`/games/vellso-dual/offline/section-lobby/${unit}`}
              className={styles.gameCard}
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url('${window.location.origin}/assets/images/games/vellso-dual-o.png')`
              }}
            >
              <div className={styles.iconCircle} style={{ background: '#FFF' }}>
                <img src={window.location.origin + '/assets/images/games/dual_icon.png'} alt='' />
              </div>
              <div className={styles.cardContent}>
                <p className={styles.cardTitle}>Vellso Dual</p>
                <p className={styles.cardDesc}>
                  Language skills: Vocabulary knowledge
                  <br />
                  It's fun and encouraging, and it helps the acquisition process.
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <PrimaryButton linkTo='/games/records' backgroundColor={colors['C212']} borderColor='transparent'>
            My Records
          </PrimaryButton>
        </div>
      </div>
      <div className='container'>
        <div className='d-flex justify-content-end mt-3'>
          <SkipButton color={colors['C2']} translate={false} onClick={navigateToNextSection} disabled={logPending} />
        </div>
      </div>
    </div>
  )
}

export default GamesSectionPage
