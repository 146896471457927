import classNames from 'classnames'
import styles from './Lock.module.scss'
import { Alignment, Fit, Layout, useRive, useStateMachineInput } from '@rive-app/react-canvas'
import { useEffect } from 'react'

const Lock = ({ onClickLock, isLocked, className }) => {
  const { rive, RiveComponent } = useRive({
    src: '/assets/rive/lock.riv',
    stateMachines: 'lock',
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.Center
    })
  })

  const unlockedInput = useStateMachineInput(rive, 'lock', 'unlocked')

  useEffect(() => {
    if (unlockedInput) {
      unlockedInput.value = isLocked
    }
  }, [isLocked, unlockedInput])

  return (
    <button className={classNames(styles.button, className)} onClick={onClickLock}>
      <RiveComponent />
    </button>
  )
}

export default Lock
