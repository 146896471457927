import { createSlice } from '@reduxjs/toolkit'
import getPlansUseCase from '../../useCase/payment/getPlansUseCase'
import applyDiscountCodeUseCase from '../../useCase/payment/applyDiscountCodeUseCase'
import plansMapper from '../../services/mapper/payment/plansMapper'
import { CUSTOM_PAY } from '../../enums/payment/paymentTypeEnums'
import getAiPackageUseCase from '../../useCase/payment/getAiPackageUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'

const initialState = {
  loading: false,
  getChooseCourseLoading: false,
  getAiPackagesLoading: false,
  error: '',
  courses: null,
  aiPackages: null,
  paymentType: CUSTOM_PAY,
  vpnOn: false
}

export const getChooseCourse = baseAsyncThunk('chooseCourse/getChooseCourse', ({ asRenewal, purchaseType }) =>
  getPlansUseCase({ asRenewal, purchaseType })
)

export const getDiscounted = baseAsyncThunk('chooseCourse/getDiscounted', ({ discountCode, asRenewal, purchaseType }) =>
  applyDiscountCodeUseCase({ discountCode, asRenewal, purchaseType })
)

export const getAiPackages = baseAsyncThunk('chooseCourse/getAiPackages', () => {
  return getAiPackageUseCase()
})

const chooseCourseSlice = createSlice({
  name: 'chooseCourse',
  initialState,
  reducers: {
    setCourses: (state, action) => {
      state.courses = plansMapper(action.payload)
    },
    setPaymentType: (state, action) => {
      state.paymentType = action.payload
    },
    setVpnOn: (state, action) => {
      state.vpnOn = action.payload
    }
  },
  extraReducers: builder => {
    // Get Choose Course
    builder.addCase(getChooseCourse.pending, state => {
      state.getChooseCourseLoading = true
    })
    builder.addCase(getChooseCourse.fulfilled, (state, action) => {
      state.getChooseCourseLoading = false
      state.courses = action.payload
      state.vpnOn = action.payload?.[0]?.vpnOn
    })
    builder.addCase(getChooseCourse.rejected, (state, action) => {
      state.getChooseCourseLoading = false
      state.error = action.error.message
    })

    // Get AI Packages
    builder.addCase(getAiPackages.pending, state => {
      state.getAiPackagesLoading = true
    })
    builder.addCase(getAiPackages.fulfilled, (state, action) => {
      state.getAiPackagesLoading = false
      state.aiPackages = action.payload
    })
    builder.addCase(getAiPackages.rejected, (state, action) => {
      state.getAiPackagesLoading = false
      state.error = action.error.message
    })

    // Get Discounted
    builder.addCase(getDiscounted.pending, state => {
      state.loading = true
    })
    builder.addCase(getDiscounted.fulfilled, (state, action) => {
      state.loading = false
      state.courses = action.payload
    })
    builder.addCase(getDiscounted.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  }
})

export const { setCourses, setPaymentType, setVpnOn } = chooseCourseSlice.actions

export default chooseCourseSlice.reducer
