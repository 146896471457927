import structureBaseTitleMapper from './structureBaseTitleMapper'
import { min } from 'lodash'
import { ContentTypesStructureEnums } from 'enums/structureEnums/templateType'
import structureBaseImageMapper from './structureBaseImageMapper'
import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'

const structureCompareSeparateTemplateMapper = ({ item, events }) => {
  const { currentViewDisplayTime: currentTemplateViewDisplayTime, nextViewDisplayTime: nextTemplateViewDisplayTime } =
    getTeacherViewEventDisplayTime(events?.view, {
      templateId: item?.id
    })

  let leftCardsDisplayTimes = []
  let rightCardsDisplayTimes = []

  return {
    ...item,
    data: {
      leftCards: item.data.leftCards?.map(card => {
        const { currentViewDisplayTime: currentCardViewDisplayTime, nextViewDisplayTime: nextCardViewDisplayTime } =
          getTeacherViewEventDisplayTime(events?.view, {
            templateId: item?.id,
            cardId: card?.id
          })

        leftCardsDisplayTimes.push(currentCardViewDisplayTime)

        switch (card.type) {
          case ContentTypesStructureEnums.TITLE:
            return {
              ...card,
              data: {
                ...card.data,
                text: structureBaseTitleMapper({ title: card?.data?.text, events })
              },
              currentViewDisplayTime: currentCardViewDisplayTime,
              nextViewDisplayTime: nextCardViewDisplayTime
            }
          case ContentTypesStructureEnums.IMAGE:
            return {
              ...card,
              data: {
                ...card.data,
                picture: structureBaseImageMapper({ image: card?.data?.picture, events }),
                pictureText: structureBaseTitleMapper({ title: card?.data?.pictureText, events })
              },
              currentViewDisplayTime: currentCardViewDisplayTime,
              nextViewDisplayTime: nextCardViewDisplayTime
            }
          case ContentTypesStructureEnums.SUBJECT:
            return {
              ...card,
              data: {
                ...card.data,
                texts: card.data.texts.map(text => {
                  return {
                    ...text,
                    text: structureBaseTitleMapper({ title: text?.text, events })
                  }
                })
              },
              currentViewDisplayTime: currentCardViewDisplayTime,
              nextViewDisplayTime: nextCardViewDisplayTime
            }
        }
      }),
      rightCards: item.data.rightCards?.map(card => {
        const { currentViewDisplayTime: currentCardViewDisplayTime, nextViewDisplayTime: nextCardViewDisplayTime } =
          getTeacherViewEventDisplayTime(events?.view, {
            templateId: item?.id,
            cardId: card?.id
          })

        rightCardsDisplayTimes.push(currentCardViewDisplayTime)

        switch (card.type) {
          case ContentTypesStructureEnums.TITLE:
            return {
              ...card,
              data: {
                ...card.data,
                text: structureBaseTitleMapper({ title: card?.data?.text, events })
              },
              currentViewDisplayTime: currentCardViewDisplayTime,
              nextViewDisplayTime: nextCardViewDisplayTime
            }
          case ContentTypesStructureEnums.IMAGE:
            return {
              ...card,
              data: {
                ...card.data,
                picture: structureBaseImageMapper({ image: card?.data?.picture, events }),
                pictureText: structureBaseTitleMapper({ title: card?.data?.pictureText, events })
              },
              currentViewDisplayTime: currentCardViewDisplayTime,
              nextViewDisplayTime: nextCardViewDisplayTime
            }
          case ContentTypesStructureEnums.SUBJECT:
            return {
              ...card,
              data: {
                ...card.data,
                texts: card.data.texts.map(text => {
                  return {
                    ...text,
                    text: structureBaseTitleMapper({ title: text?.text, events })
                  }
                })
              },
              currentViewDisplayTime: currentCardViewDisplayTime,
              nextViewDisplayTime: nextCardViewDisplayTime
            }
        }
      }),
      leftCardsDisplayTime: Math.max(min(leftCardsDisplayTimes), currentTemplateViewDisplayTime) || 0,
      rightCardsDisplayTime: Math.max(min(rightCardsDisplayTimes), currentTemplateViewDisplayTime) || 0
    },
    currentViewDisplayTime: currentTemplateViewDisplayTime,
    nextViewDisplayTime: nextTemplateViewDisplayTime
  }
}

export default structureCompareSeparateTemplateMapper
