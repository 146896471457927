import componentStyle from './StructureTextAreaComponent.module.scss'
import HtmlTextWrapper from '../../../../../components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from '../../../../../data'
import { useContext, useEffect, useRef } from 'react'
import colorsContext from '../../../../../contexts/ColorsContext'
import classNames from 'classnames'
import structuresContext from '../../../../../contexts/StructuresContext'
import { robotTeacherScrollIntoViewByElement } from '../../../../../operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'

const StructureTextAreaComponent = ({ text }) => {
  const { colors } = useContext(colorsContext)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)

  const currentTime = roboTeacherData?.currentTime ?? 0
  // Current time is between currentViewDisplayTime and nextViewDisplayTime
  const isHidden = isRoboTeacher ?? false ? currentTime < (text?.currentViewDisplayTime ?? 0) : false
  const isDisplayingTime = !isHidden && currentTime < text?.nextViewDisplayTime

  const elRef = useRef(null)

  useEffect(() => {
    if (text?.currentViewDisplayTime > 0 && isDisplayingTime && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isDisplayingTime])

  if (text) {
    return (
      <div
        ref={elRef}
        className={classNames(componentStyle.textAreaComponent, { ['hide-element']: isHidden })}
        style={{ background: colors['C555'] }}
      >
        <HtmlTextWrapper
          data={text}
          moreOptionsList={moreOptionsList}
          showPhraseOptions
          textColor={colors['C23']}
          optionsColor={colors['C23']}
        />
      </div>
    )
  } else {
    return null
  }
}

export default StructureTextAreaComponent
