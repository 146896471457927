import React, { useContext, useEffect, useRef } from 'react'
import structureStyle from './../StructuredComponents.module.scss'
import componentStyle from './CompareSeparateTemplate.module.scss'
import colorsContext from '../../../../contexts/ColorsContext'
import StructureTitleComponent from '../Components/StructureTitleComponent/StructureTitleComponent'
import StructureImageComponent from '../Components/StructureImageComponent/StructureImageComponent'
import StructureSubjectsComponent from '../Components/StructureSubjectsComponent/StructureSubjectsComponent'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { robotTeacherScrollIntoViewByElement } from '../../../../operations/robotTeacherScrollIntoViewByElement'
import structuresContext from '../../../../contexts/StructuresContext'
import { useSelector } from 'react-redux'
import { ContentTypesStructureEnums } from '../../../../enums/structureEnums/templateType'

const CompareSeparateTemplate = ({ template }) => {
  const { colors } = useContext(colorsContext)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const elRef = useRef(null)

  const renderCardContent = card => {
    const cardElRef = useRef(null)
    const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
    const cardIsHidden =
      isRoboTeacher ?? false ? (roboTeacherData?.currentTime ?? 0) < (card?.currentViewDisplayTime ?? 0) : false

    // Current time is between currentViewDisplayTime and nextViewDisplayTime
    const isCardDisplayingTime = !cardIsHidden && (roboTeacherData?.currentTime ?? 0) < card?.nextViewDisplayTime

    useEffect(() => {
      if (card?.nextViewDisplayTime > 0 && isCardDisplayingTime && isRoboTeacher && !scrolling) {
        robotTeacherScrollIntoViewByElement(cardElRef?.current)
      }
    }, [isCardDisplayingTime])

    switch (card?.type) {
      case ContentTypesStructureEnums.TITLE:
        return (
          <div
            ref={cardElRef}
            className={classNames('structureStyle.roboTeacherView', { ['hide-element']: cardIsHidden })}
          >
            <StructureTitleComponent text={card.data?.text} />
          </div>
        )
      case ContentTypesStructureEnums.IMAGE:
        return (
          <div
            ref={cardElRef}
            className={classNames('structureStyle.roboTeacherView', { ['hide-element']: cardIsHidden })}
          >
            <StructureImageComponent picture={{ ...card.data?.picture, text: card.data?.pictureText }} />
          </div>
        )
      case ContentTypesStructureEnums.SUBJECT:
        return (
          <div
            ref={cardElRef}
            className={classNames('structureStyle.roboTeacherView', { ['hide-element']: cardIsHidden })}
          >
            <StructureSubjectsComponent subjects={[card.data]} />
          </div>
        )
    }
  }

  const renderCards = (cards, currentViewDisplayTime, nextViewDisplayTime) => {
    const isHidden =
      isRoboTeacher ?? false ? (roboTeacherData?.currentTime ?? 0) < (currentViewDisplayTime ?? 0) : false

    // Current time is between currentViewDisplayTime and nextViewDisplayTime
    const isDisplayingTime = !isHidden && (roboTeacherData?.currentTime ?? 0) < nextViewDisplayTime

    useEffect(() => {
      if (template?.currentViewDisplayTime > 0 && isDisplayingTime && isRoboTeacher && !scrolling) {
        robotTeacherScrollIntoViewByElement(elRef?.current)
      }
    }, [isDisplayingTime])

    return (
      <div
        ref={elRef}
        className={classNames(
          structureStyle.templateContainer,
          { [structureStyle.border]: !isEmpty(template?.config?.borderColor) },
          { ['hide-element']: isHidden }
        )}
        style={{
          background: isEmpty(template?.config?.backgroundColor) ? colors['C194'] : template.config.backgroundColor,
          borderColor: template?.config?.borderColor
        }}
      >
        {cards.map((card, i) => {
          return <React.Fragment key={i}>{renderCardContent(card)}</React.Fragment>
        })}
      </div>
    )
  }

  return (
    <div className='container container-1'>
      <div className={componentStyle.cardsContainer} data-rt-id={template?.id}>
        {renderCards(template.data.leftCards, template.data.leftCardsDisplayTime, template.nextViewDisplayTime)}
        {renderCards(template.data.rightCards, template.data.rightCardsDisplayTime, template.nextViewDisplayTime)}
      </div>
    </div>
  )
}

export default CompareSeparateTemplate
