// thunkRegistry.js
const thunkActionRegistry = {}

export const registerThunk = (typePrefix, thunk) => {
  thunkActionRegistry[typePrefix] = thunk
}

export const getThunkById = thunkId => {
  return thunkActionRegistry[thunkId]
}
