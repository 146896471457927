import structureBaseTitleMapper from './structureBaseTitleMapper'
import { min } from 'lodash'
import structureBaseImageMapper from './structureBaseImageMapper'
import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'

const structureImageTemplateMapper = ({ item, events }) => {
  const { currentViewDisplayTime, nextViewDisplayTime } = getTeacherViewEventDisplayTime(events?.view, {
    templateId: item?.id
  })

  return {
    ...item,
    data: {
      cards: item.data.cards.map(card => {
        const { currentViewDisplayTime: currentCardViewDisplayTime, nextViewDisplayTime: nextCardViewDisplayTime } =
          getTeacherViewEventDisplayTime(events?.view, {
            templateId: item?.id,
            cardId: card?.id
          })

        return {
          ...card,
          title: structureBaseTitleMapper({ title: card?.title, events }),
          images: card.images.map(image => {
            return {
              ...image,
              text: image?.text ? structureBaseTitleMapper({ title: image?.text, events }) : null,
              picture: structureBaseImageMapper({ image: image?.picture, text: image?.text, events })
            }
          }),
          currentViewDisplayTime: currentCardViewDisplayTime,
          nextViewDisplayTime: nextCardViewDisplayTime
        }
      })
    },
    currentViewDisplayTime,
    nextViewDisplayTime
  }
}

export default structureImageTemplateMapper
