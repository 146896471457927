import HtmlTextWrapper from 'components/HtmlTextWrapper/HtmlTextWrapper'
import { moreOptionsList } from 'data'
import { useContext, useEffect, useRef } from 'react'
import colorsContext from 'contexts/ColorsContext'
import componentStyle from './StructureTextComponent.module.scss'
import classNames from 'classnames'
import structuresContext from 'contexts/StructuresContext'
import { robotTeacherScrollIntoViewByElement } from 'operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'

const StructureTextComponent = ({ text }) => {
  const { colors } = useContext(colorsContext)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const currentTime = roboTeacherData?.currentTime ?? 0
  const isHidden = isRoboTeacher ?? false ? currentTime < (text?.currentViewDisplayTime ?? 0) : false

  // Current time is between currentViewDisplayTime and nextViewDisplayTime
  const isDisplayingTime = !isHidden && currentTime < text?.nextViewDisplayTime

  const elRef = useRef(null)

  useEffect(() => {
    if (text?.currentViewDisplayTime > 0 && isDisplayingTime && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isDisplayingTime])

  if (text) {
    return (
      <div
        ref={elRef}
        className={classNames(componentStyle.textComponent, { ['hide-element']: isHidden })}
        style={{ borderColor: colors['C242'] }}
      >
        <HtmlTextWrapper
          data={text}
          moreOptionsList={moreOptionsList}
          showPhraseOptions
          textColor={colors['C23']}
          optionsColor={colors['C23']}
        />
      </div>
    )
  } else {
    return null
  }
}

export default StructureTextComponent
