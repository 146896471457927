import { useContext, useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchWriting, clearStates as clearWritingStates } from '../../redux/features/writingSlice'
import WritingSvg from '../../icons/WritingSvg'
import ArrowMoreSvg from '../../icons/ArrowMoreSvg'
import ToplineTitle from '../../components/ToplineTitle/ToplineTitle'
import WritingSampleSvg from '../../icons/WritingSampleSvg'
import HelpButton from '../../components/HelpButton/HelpButton'
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton'
import HtmlTextWrapper from '../../components/HtmlTextWrapper/HtmlTextWrapper'
import colorsContext from '../../contexts/ColorsContext'
import {
  changeGroupType,
  goToNextItem,
  resetStates as resetExerciseStates,
  toggleViewType
} from '../../redux/features/exerciseSlice'
import ExerciseSection from './Exercises/ExerciseSection'
import TemplateViewTypeButton from '../../components/TemplateViewTypeButton/TemplateViewTypeButton'
import { pageGuidEnums } from '../../enums/pageGuideEnums/pageGuideEnums'
import useAppUser from '../../hooks/useAppUser'
import { TYPE_A, TYPE_B } from '../../enums/exerciseEnums/exerciseGroupTypeEnums'
import useEnhancedDispatch from '../../hooks/useDispatchWrapper'

const Writing = () => {
  require('./writing.scss')

  const { colors } = useContext(colorsContext)
  const states = useSelector(state => state.writing)
  const exerciseStates = useSelector(state => state.exercise)
  const [showPractice, setShowPractice] = useState(false)
  const { appUserData } = useAppUser()
  const { enhancedDispatch } = useEnhancedDispatch()

  const dispatch = useDispatch()
  const { unit } = useParams()

  const [accordionStates, setAccordionStates] = useState({
    accordion1: true,
    accordion2: true
  })

  useEffect(() => {
    enhancedDispatch(fetchWriting, { unit })

    return () => {
      dispatch(clearWritingStates())
    }
  }, [dispatch, unit])

  const onClickPractice = () => {
    setShowPractice(true)
  }

  const toggleAccordion = key => {
    setAccordionStates(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }))
  }

  const WritingAccordion = ({ data, type, accordionKey }) => {
    const isOpen = accordionStates[accordionKey] || false

    return (
      <Accordion activeKey={isOpen ? '0' : null} className='writing-accordion'>
        <Accordion.Item eventKey='0'>
          <span className='top-border' style={{ background: colors['C158'] }} />
          <div className='wa-card' style={{ background: colors['C54'] }}>
            <Accordion.Header onClick={() => toggleAccordion(accordionKey)}>
              <div className='card-header'>
                <h4 className='title' style={{ color: colors['C158'], display: 'flex', alignItems: 'center' }}>
                  <span className='writing-icon'>
                    {type === 'Writing Tips' || type === 'Writing Tip & Sample' ? (
                      <WritingSvg color={colors['C158']} />
                    ) : (
                      <WritingSampleSvg color={colors['C158']} />
                    )}
                  </span>
                  {type}
                </h4>
                <span className='arrow'>
                  <ArrowMoreSvg color={colors['C158']} />
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body className='card-main-wrapper'>
              <div style={{ overflow: 'hidden' }}>
                <div className='card-main'>
                  <ul className='mb-0 p-0'>
                    {data.map((item, i) => (
                      <li key={i}>
                        <HtmlTextWrapper
                          showPhraseOptions
                          textColor={colors['C230']}
                          optionsColor={colors['C230']}
                          moreOptionsList={['TRANSLATE']}
                          data={item}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Accordion.Body>
          </div>
        </Accordion.Item>
      </Accordion>
    )
  }

  if (states.data != null) {
    return (
      <div className='pt-3' data-jsx='Writing'>
        <div className='container-fluid'>
          <div className='d-flex justify-content-end'>
            {appUserData?.access?.roles?.includes('app_administrator') && (
              <button
                className='font-weight-600 px-2 rounded-pill fs-sm'
                style={{ color: colors['C2'], background: 'transparent', border: `1px solid ${colors['C2']}` }}
                onClick={() => {
                  dispatch(resetExerciseStates())
                  dispatch(changeGroupType())
                }}
              >
                {exerciseStates.exerciseGroupType === TYPE_A && 'Type A'}
                {exerciseStates.exerciseGroupType === TYPE_B && 'Type B'}
              </button>
            )}
            {showPractice && exerciseStates.data.paginable && (
              <div className='me-3 me-md-4 ms-2'>
                <TemplateViewTypeButton
                  onClick={() => {
                    dispatch(toggleViewType())
                    if (
                      exerciseStates.isSingleTemplate &&
                      exerciseStates.data.stacks[exerciseStates.currentItem.stackIndex].items[
                        exerciseStates.currentItem.itemIndex
                      ].checked
                    ) {
                      dispatch(goToNextItem())
                    }
                  }}
                  isSingle={exerciseStates.isSingleTemplate}
                  color={colors['C2']}
                />
              </div>
            )}
            <div className='me-3 me-md-5 ms-2'>
              <HelpButton color={colors['C2']} pageType={pageGuidEnums.WRITING_SECTION} />
            </div>
          </div>
        </div>
        <ToplineTitle titleData={states.data.title} />
        <div className='position-relative mt-4'>
          <div className='container container-1'>
            <div className='writing-openpage-animation' style={{ background: colors['C188'] }} />
            <div className='writing-display-after-animation'>
              {states.data?.writingTips?.length > 0 && (
                <WritingAccordion
                  type={states.data.writingSamples.length ? 'Writing Tips' : 'Writing Tip & Sample'}
                  data={states.data.writingTips}
                  accordionKey='accordion1' // Unique key for this accordion
                />
              )}
              {states.data?.writingSamples?.length > 0 && (
                <WritingAccordion type='Sample' data={states.data.writingSamples} accordionKey='accordion2' />
              )}
            </div>
          </div>
          {showPractice && (
            <>
              <div className='exercise-line'>
                <p style={{ color: colors['C187'] }}>Exercise</p>
              </div>
              <div className='container-fluid'>
                <ExerciseSection sectionName='WRITING_EXERCISE_11' hideHeader />
              </div>
            </>
          )}
        </div>
        {!showPractice && (
          <div className='d-flex justify-content-center mb-2'>
            <PrimaryButton onClick={onClickPractice}>Practice</PrimaryButton>
          </div>
        )}
      </div>
    )
  } else {
    return 'Loading...'
  }
}

export default Writing
