import componentStyle from './CustomTitleTemplate.module.scss'
import HtmlTextWrapper from 'components/HtmlTextWrapper/HtmlTextWrapper'
import { useContext, useEffect, useRef } from 'react'
import colorsContext from 'contexts/ColorsContext'
import { moreOptionsList } from 'data'
import structuresContext from 'contexts/StructuresContext'
import classNames from 'classnames'
import { robotTeacherScrollIntoViewByElement } from 'operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'

const CustomTitleTemplate = ({ template }) => {
  const { colors } = useContext(colorsContext)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const currentTime = roboTeacherData?.currentTime ?? 0
  const isHidden = isRoboTeacher ?? false ? currentTime < (template?.currentViewDisplayTime ?? 0) : false

  // Current time is between currentViewDisplayTime and nextViewDisplayTime
  const isDisplayingTime = !isHidden && currentTime < template?.nextViewDisplayTime

  const elRef = useRef(null)

  useEffect(() => {
    if (template?.currentViewDisplayTime > 0 && isDisplayingTime && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isDisplayingTime])

  if (template) {
    return (
      <div className='container container-1'>
        <div
          ref={elRef}
          data-rt-id={template?.id}
          className={classNames(componentStyle.customTitle, { ['hide-element']: isHidden })}
          style={{
            borderColor: template.config.borderColor,
            backgroundColor: template.config.backgroundColor ?? 'transparent'
          }}
        >
          <HtmlTextWrapper
            data={template.data.title}
            showPhraseOptions
            moreOptionsList={moreOptionsList}
            textColor={colors['C187']}
            optionsColor={colors['C2']}
          />
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default CustomTitleTemplate
