import { generateExerciseTemplateClassName, htmlTextConversion } from '../mapperConversions'
import htmlToText from '../../../operations/htmlToText'
import { pageGuidEnums } from '../../../enums/pageGuideEnums/pageGuideEnums'

const fetchComboPictionaryMapper = data => {
  return {
    pageType: pageGuidEnums.COMBO_BOX_EXERCISE,
    data: {
      immediateCheck: true,
      paginable: true,
      type: data.type,
      title: data.title ?? null,
      stacks: [...data.exerciseStacks].map(stack => {
        return {
          id: stack.id,
          title: stack.title,
          joined: stack.joined,
          items: [...stack.items].map(item => {
            const given = item.data.givens[0]
            let fullTextAnswer = null
            return {
              id: item.id,
              checked: false,
              unlocked: false,
              given: {
                ...given,
                index: given.index,
                template: given.template,
                templateClassName: generateExerciseTemplateClassName(given.type, given.template),
                text: htmlTextConversion(given.text),
                imageUri: given.imageUri ?? null
              },
              choices: [...item.data.choices].map((choices, choicesIndex) => {
                return {
                  index: choices.index,
                  userAnswer: null,
                  items: [...choices.items].map(choice => {
                    const isAnswer = item.data.answers[choicesIndex].items.find(
                      answer => htmlToText(answer.text.sentence) === htmlToText(choice.text.sentence)
                    )
                    if (isAnswer) {
                      fullTextAnswer = htmlTextConversion(choice.text)
                    }
                    return {
                      index: choice.index,
                      text: htmlTextConversion(choice.text),
                      selected: false,
                      isAnswer
                    }
                  })
                }
              }),
              fullTextAnswer
            }
          })
        }
      }),
      checked: false,
      checkable: false
    }
  }
}

export default fetchComboPictionaryMapper
