const waitForOnline = async () => {
  return new Promise(resolve => {
    // If the user is already online, resolve immediately
    if (navigator.onLine) {
      resolve('online')
      return
    }

    // Otherwise, listen for the "online" event
    const handleOnline = () => {
      window.removeEventListener('online', handleOnline) // Clean up the event listener after it's triggered
      resolve('online')
    }

    // Add the event listener for the "online" event
    window.addEventListener('online', handleOnline)
  })
}

export default waitForOnline
