import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'
import structureBaseTitleMapper from './structureBaseTitleMapper'

const structureTitleTemplateMapper = ({ item, events }) => {
  const { currentViewDisplayTime, nextViewDisplayTime } = getTeacherViewEventDisplayTime(events?.view, {
    templateId: item?.id
  })

  return {
    ...item,
    data: {
      title: structureBaseTitleMapper({ title: item?.data?.title, events })
    },
    currentViewDisplayTime,
    nextViewDisplayTime
  }
}

export default structureTitleTemplateMapper
