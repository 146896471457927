import { createSlice } from '@reduxjs/toolkit'
import aiChatRoleDescriptionUseCase from '../../useCase/aiChatRoleDescriptionUseCase/aiChatRoleDescriptionUseCase'
import aiPromptUseCase from '../../useCase/aiPromptUseCase/aiPromptUseCase'
import aiPromptFeedbackUseCase from '../../useCase/aiPromptFeedbackUseCase/aiPromptFeedbackUseCase'
import { LIKE } from '../../enums/AIFeedbackEnums/AIFeedbackEnums'
import aiExerciseUseCase from '../../useCase/aiExerciseUseCase/aiExerciseUseCase'
import { baseAsyncThunk } from 'redux/baseAsyncThunk'

const initialState = {
  loading: false,
  subjects: null,
  selectedSubject: null,
  promptId: null,
  pendingChat: false,
  dialogs: [],
  assistance: [],
  outOfCredit: false,
  isFinished: false,
  error: ''
}

export const getAiChatRoleDescriptions = baseAsyncThunk(
  'aiChat/getAiChatRoleDescriptions',
  ({ unitId, promptType }) => {
    return aiChatRoleDescriptionUseCase({ unitId, promptType })
  }
)

export const sendAiChatPrompt = baseAsyncThunk(
  'aiChat/sendAiChatPrompt',
  async ({ content, promptType }, { rejectWithValue, fulfillWithValue, getState }) => {
    const { aiChat } = getState()
    return aiPromptUseCase({
      content: content,
      promptType: promptType,
      roleDescriptionId: aiChat.selectedSubject?.id ?? null,
      promptId: aiChat.promptId
    })
      .then(value => {
        return fulfillWithValue(value)
      })
      .catch(err => {
        return rejectWithValue(err.response)
      })
  }
)

export const sendAiPromptFeedback = baseAsyncThunk(
  'aiChat/sendAiPromptFeedback',
  async ({ promptId, aiPromptDetailId, feedbackType, text, isExerciseAssistance }, { getState }) => {
    const { aiChat } = getState()
    const response = await aiPromptFeedbackUseCase({
      aiPromptDetailId,
      feedbackType,
      text,
      aiPromptId: aiChat.promptId
    })

    return {
      response,
      isExerciseAssistance,
      promptId
    }
  }
)

export const getExerciseAssistant = baseAsyncThunk(
  'aiChat/getExerciseAssistant',
  async ({ exerciseItemId, correctAnswer, userAnswer }, { rejectWithValue, fulfillWithValue }) => {
    return aiExerciseUseCase({
      exerciseItemId,
      correctAnswer,
      userAnswer
    })
      .then(value => {
        return fulfillWithValue({ ...value, exerciseItemId })
      })
      .catch(err => {
        return rejectWithValue(err.response)
      })
  }
)

const aiChatSlice = createSlice({
  name: 'aiChat',
  initialState,
  reducers: {
    setIsFinishedAIChat: (state, action) => {
      state.isFinished = action.payload
    },
    setSelectedSubject: (state, action) => {
      state.selectedSubject = action.payload
    },
    pushToDialogs: (state, action) => {
      if (action.payload.checkLastDialog) {
        if (action.payload.isRobot === state.dialogs[state.dialogs.length - 1]?.isRobot) {
          return
        }
      }
      state.dialogs.push({
        id: action.payload.id,
        index: state.dialogs.length,
        content: {
          sentence: action.payload.content
        },
        isTyping: action.payload.isTyping,
        isRobot: action.payload.isRobot,
        showFeedbackButtons: false,
        feedback: null
      })
    },
    updateLastRobotDialogToGenerating: state => {
      const lastDialog = state.dialogs[state.dialogs.length - 1]
      if (lastDialog?.isRobot) {
        state.dialogs[state.dialogs.length - 1] = {
          ...lastDialog,
          index: state.dialogs.length,
          content: {
            sentence: ''
          },
          isTyping: true,
          showFeedbackButtons: false,
          feedback: null,
          isError: false
        }
      }
    },
    resetAiChatStates: state => {
      state.loading = false
      state.subjects = null
      state.selectedSubject = null
      state.promptId = null
      state.pendingChat = false
      state.dialogs = []
      state.outOfCredit = false
      state.isFinished = false
      state.error = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(getAiChatRoleDescriptions.pending, state => {
      state.loading = true
    })
    builder.addCase(getAiChatRoleDescriptions.fulfilled, (state, action) => {
      state.loading = false
      state.subjects = action.payload
    })
    builder.addCase(getAiChatRoleDescriptions.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(sendAiChatPrompt.pending, state => {
      state.loading = true
    })
    builder.addCase(sendAiChatPrompt.fulfilled, (state, action) => {
      state.loading = false
      const lastDialog = state.dialogs[state.dialogs.length - 1]
      if (lastDialog?.isRobot && lastDialog?.isTyping) {
        state.dialogs[state.dialogs.length - 1] = {
          ...lastDialog,
          id: action.payload?.id,
          content: {
            sentence: action.payload?.content
          },
          isTyping: false,
          showFeedbackButtons: true,
          isError: false
        }
      } else {
        state?.dialogs?.push({
          id: action.payload?.id,
          index: state?.dialogs?.length ?? 0,
          content: {
            sentence: action.payload?.content
          },
          isTyping: false,
          isRobot: true,
          showFeedbackButtons: true,
          feedback: null,
          isError: false
        })
      }

      state.promptId = action.payload?.aiPromptId
    })
    builder.addCase(sendAiChatPrompt.rejected, (state, action) => {
      state.loading = false

      if (action?.payload?.status === 402) {
        state.outOfCredit = true
      } else {
        const lastDialog = state.dialogs[state.dialogs.length - 1]
        if (lastDialog?.isRobot && lastDialog?.isTyping) {
          state.dialogs[state.dialogs.length - 1] = {
            ...lastDialog,
            content: {
              sentence: 'Something went wrong.'
            },
            isTyping: false,
            showFeedbackButtons: false,
            isError: true
          }
        } else {
          state?.dialogs?.push({
            id: null,
            index: state?.dialogs?.length ?? 0,
            content: {
              sentence: 'Something went wrong.'
            },
            isTyping: false,
            isRobot: true,
            showFeedbackButtons: false,
            isError: true
          })
        }
      }
    })
    builder.addCase(getExerciseAssistant.pending, state => {
      state.loading = true
    })
    builder.addCase(getExerciseAssistant.fulfilled, (state, action) => {
      state.loading = false

      const newAssistanceData = {
        promptId: action.payload?.aiPromptId,
        id: action.payload?.id,
        exerciseItemId: action.payload?.exerciseItemId,
        content: {
          sentence: action.payload?.content
        },
        userAnswer: action.payload?.userAnswer,
        feedback: null
      }
      const previousAssistanceIndex = state.assistance?.findIndex(
        it => it.exerciseItemId === action.payload?.exerciseItemId
      )
      if (previousAssistanceIndex >= 0) {
        state.assistance[previousAssistanceIndex] = newAssistanceData
      } else {
        state.assistance = [...state.assistance, newAssistanceData]
      }
    })
    builder.addCase(getExerciseAssistant.rejected, (state, action) => {
      state.loading = false
      if (action?.payload?.status === 409) {
        state.outOfCredit = true
      }
      state.error = action?.error?.message
    })
    builder.addCase(sendAiPromptFeedback.pending, state => {
      state.loading = true
    })
    builder.addCase(sendAiPromptFeedback.fulfilled, (state, action) => {
      state.loading = false

      if (action.payload.isExerciseAssistance) {
        state.assistance = state.assistance.map(it => {
          if (it.id === action.payload.promptId) {
            return {
              ...it,
              feedback: {
                liked: action.payload.response.feedbackType === LIKE,
                type: action.payload.response.feedbackType
              }
            }
          }
          return it
        })
      } else {
        state.dialogs = [...state.dialogs].map(dialog => {
          if (dialog.id === action.payload.response.aiPromptDetailId) {
            return {
              ...dialog,
              feedback: {
                liked: action.payload.response.feedbackType === LIKE,
                type: action.payload.response.feedbackType
              }
            }
          }
          return dialog
        })
      }
    })
    builder.addCase(sendAiPromptFeedback.rejected, (state, action) => {
      state.loading = false
      state.error = action?.error?.message
    })
  }
})

export const {
  setSelectedSubject,
  pushToDialogs,
  resetAiChatStates,
  setIsFinishedAIChat,
  updateLastRobotDialogToGenerating
} = aiChatSlice.actions

export default aiChatSlice.reducer
