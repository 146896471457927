import serviceCall from 'services'
import todayStudyMapper from 'services/mapper/todayStudy/todayStudyMapper'

const homeTodayStudyUseCase = async () => {
  const result = await serviceCall('fetchHomeTodayStudy', null, 'cloud')

  return todayStudyMapper(result)
}

export default homeTodayStudyUseCase
