import { useDispatch } from 'react-redux'
import SolidLockSvg from 'icons/SolidLockSvg'
import { useContext } from 'react'
import colorsContext from 'contexts/ColorsContext'
import { setCurrentOpenModal } from 'redux/features/modalSlice'
import componentStyle from './SectionLockedIcon.module.scss'
import { globalModalEnums } from 'enums/globalEnums/globalEnums'

const SectionLockedIcon = ({ color, nonButton = false }) => {
  const { colors } = useContext(colorsContext)
  const dispatch = useDispatch()

  if (nonButton) {
    return (
      <div className={componentStyle.icon}>
        <SolidLockSvg color={color ?? colors['C2'] + '80'} />
      </div>
    )
  }
  return (
    <button
      className={componentStyle.icon}
      onClick={() => dispatch(setCurrentOpenModal(globalModalEnums.subscriptionUpgrade))}
    >
      <SolidLockSvg color={color ?? colors['C2'] + '80'} />
    </button>
  )
}

export default SectionLockedIcon
