import { useContext, useEffect, useRef } from 'react'
import componentStyle from './InstructionTemplate.module.scss'
import Instruction from 'components/Instruction/Instruction'
import structuresContext from 'contexts/StructuresContext'
import classNames from 'classnames'
import { robotTeacherScrollIntoViewByElement } from 'operations/robotTeacherScrollIntoViewByElement'
import { useSelector } from 'react-redux'

const InstructionTemplate = ({ template }) => {
  const elRef = useRef(null)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const currentTime = roboTeacherData?.currentTime ?? 0
  const isHidden = isRoboTeacher ?? false ? currentTime < (template?.currentViewDisplayTime ?? 0) : false
  // Current time is between currentViewDisplayTime and nextViewDisplayTime
  const isDisplayingTime = !isHidden && currentTime < template?.nextViewDisplayTime

  useEffect(() => {
    if (template?.currentViewDisplayTime > 0 && isDisplayingTime && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isDisplayingTime])

  return (
    <div
      ref={elRef}
      data-rt-id={template.id}
      className={classNames(componentStyle.container, { ['hide-element']: isHidden })}
    >
      <Instruction data={template.data?.title} />
    </div>
  )
}

export default InstructionTemplate
