import structureBaseTitleMapper from './structureBaseTitleMapper'
import structureBaseImageMapper from './structureBaseImageMapper'
import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'

const structureBubbleImageTemplateMapper = ({ item, events }) => {
  const { currentViewDisplayTime, nextViewDisplayTime } = getTeacherViewEventDisplayTime(events?.view, {
    templateId: item?.id
  })

  return {
    ...item,
    data: {
      ...item.data,
      picture: structureBaseImageMapper({ image: item?.data?.picture, text: item?.data?.pictureText, events }),
      pictureText: structureBaseTitleMapper({ text: item?.data?.pictureText, events }),
      title: structureBaseTitleMapper({ title: item?.data?.title, events }),
      bubbleDescriptions: item.data.bubbleDescriptions.map(description => {
        return {
          ...description,
          text: structureBaseTitleMapper({ title: description?.text, events })
        }
      }),
      texts: item.data.texts.map(text => {
        return {
          ...text,
          text: structureBaseTitleMapper({ title: text?.text, events })
        }
      })
    },
    currentViewDisplayTime,
    nextViewDisplayTime
  }
}

export default structureBubbleImageTemplateMapper
