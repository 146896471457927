export const getLocalStorageUserHasPlan = () => {
  const userHasPlan = localStorage.getItem('userHasPlan')
  try {
    if (typeof userHasPlan === 'string') {
      return JSON.parse(userHasPlan) || false // default to false
    }
    return false
  } catch (e) {
    return false
  }
}

export const setLocalStorageUserHasPlan = (userHasPlan: boolean) => {
  localStorage.setItem('userHasPlan', JSON.stringify(Boolean(userHasPlan)))
}
