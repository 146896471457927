import { ContentTypesStructureEnums } from 'enums/structureEnums/templateType'
import structureTitleTemplateMapper from './structureTitleTemplateMapper'
import structureTextTemplateMapper from './structureTextTemplateMapper'
import structureGeneralTemplateMapper from './structureGeneralTemplateMapper'
import structureBubbleImageTemplateMapper from './structureBubbleImageTemplateMapper'
import structureTableTemplateMapper from './structureTableTemplateMapper'
import structureSingleImageTemplateMapper from './structureSingleImageTemplateMapper'
import structureImageTemplateMapper from './structureImageTemplateMapper'
import structureCompareMonolithicTemplateMapper from './structureCompareMonolithicTemplateMapper'
import structureCompareSeparateTemplateMapper from './structureCompareSeparateTemplateMapper'
import structureDividerTemplateMapper from './structureDividerTemplateMapper'
import makeUrl from 'operations/makeUrl'
import getCachedMediaUrl from 'operations/getCachedMediaUrl'
import structureButtonTemplateMapper from './structureButtonTemplateMapper'
import roboTeacherTranscriptionConvertor from './roboTeacherTranscriptionConvertor'
import { sortBy } from 'lodash'

const roboTeacherMapper = async data => {
  const englishVoiceUri =
    data.englishRobotTeacher?.editedVoiceUri ?? data.englishRobotTeacher?.robotTeacherData?.voiceUri
  const nativeVoiceUri = data.nativeRobotTeacher?.editedVoiceUri ?? data.nativeRobotTeacher?.robotTeacherData?.voiceUri

  const englishVoiceURL = await getCachedMediaUrl(makeUrl(englishVoiceUri))
  const nativeVoiceURL = await getCachedMediaUrl(makeUrl(nativeVoiceUri))

  const templatesMapper = d => {
    if (d?.events?.view?.length > 0) {
      d.events.view = sortBy(d.events.view, it => parseInt(it.startTime) || 0)
    }

    return d?.templates?.map(item => {
      switch (item.type) {
        case ContentTypesStructureEnums.TITLE:
          return structureTitleTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.TEXT:
          return structureTextTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.GENERAL:
          return structureGeneralTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.SINGLE_IMAGE:
          return structureSingleImageTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.IMAGES:
          return structureImageTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.BUBBLE_IMAGE:
          return structureBubbleImageTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.TABLE:
          return structureTableTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.BUTTON:
          return structureButtonTemplateMapper({ item, events: d.events })
        case ContentTypesStructureEnums.COMPARE_MONOLITHIC:
          return structureCompareMonolithicTemplateMapper({
            item,
            events: d.events
          })
        case ContentTypesStructureEnums.COMPARE_SEPARATE:
          return structureCompareSeparateTemplateMapper({
            item,
            events: d.events
          })
        case ContentTypesStructureEnums.DIVIDER:
          return structureDividerTemplateMapper({ item, events: d.events })
      }
    })
  }

  const nativeLanguage = data.nativeRobotTeacher?.nativeLanguage

  return {
    english: {
      voice: englishVoiceURL,
      description: data.englishRobotTeacher.robotTeacherData?.description,
      templates: templatesMapper(data.englishRobotTeacher.robotTeacherData),
      view: data.englishRobotTeacher.robotTeacherData?.events?.view,
      subTitles: data.englishRobotTeacher.robotTeacherData?.events?.subtitle,
      pauseTimes: data.englishRobotTeacher.robotTeacherData?.events?.voice,
      robot:
        data.englishRobotTeacher.robotTeacherData?.events?.robot?.sort(
          (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
        ) ?? []
    },
    nativeData: {
      voice: nativeVoiceURL,
      description: roboTeacherTranscriptionConvertor(
        data.nativeRobotTeacher.robotTeacherData?.description,
        nativeLanguage
      ),
      templates: templatesMapper(data.nativeRobotTeacher.robotTeacherData),
      view: data.nativeRobotTeacher.robotTeacherData?.events?.view,
      subTitles: data.nativeRobotTeacher.robotTeacherData?.events?.subtitle?.map(subtitle => {
        return {
          ...subtitle,
          sentence: roboTeacherTranscriptionConvertor(subtitle?.sentence, nativeLanguage)
        }
      }),
      pauseTimes: data.nativeRobotTeacher.robotTeacherData?.events?.voice,
      robot:
        data.nativeRobotTeacher.robotTeacherData?.events?.robot?.sort(
          (a, b) => parseInt(a.startTime) - parseInt(b.startTime)
        ) ?? [],
      nativeLanguage
    }
  }
}

export default roboTeacherMapper
