import serviceCall from '../../services'
import dictionaryMapper from '../../services/mapper/dictionary/dictionaryMapper'
import { transliterate } from 'transliteration'
import nativeDictionaryMapper from '../../services/mapper/dictionary/nativeDictionaryMapper'
import * as Sentry from '@sentry/react'

const dictionaryUseCase = async ({ phrase, shouldTransliterate }) => {
  let data

  if (shouldTransliterate) {
    data = await serviceCall('fetchDictionary', { phrase: transliterate(phrase) }, 'cloud')
  } else {
    data = await serviceCall('fetchDictionary', { phrase: phrase }, 'cloud')
  }

  if (data?.freeDictionaryDTO === null) {
    Sentry.captureMessage(`Word data from free dictionary service is empty for: ${phrase}`, {
      level: 'warning',
      extra: { phrase, url: window.location.href }
    })
  }
  if (!data?.vellsoDictionary || data?.length === 0) {
    Sentry.captureMessage(`Word data from vellso (native) dictionary service is empty for: ${phrase}`, {
      level: 'warning',
      extra: { phrase, url: window.location.href }
    })
  }

  return {
    vellsoDictionary: data?.vellsoDictionary ? nativeDictionaryMapper(data?.vellsoDictionary) : null,
    freeDictionary: data?.freeDictionaryDTO ? dictionaryMapper(data?.freeDictionaryDTO) : null,
    phrase,
    voices: {
      US: null,
      UK: null
    }
  }
}

export default dictionaryUseCase
