import getTeacherViewEventDisplayTime from 'util/getTeacherViewEventDisplayTime'

const structureDividerTemplateMapper = ({ item, events }) => {
  const { currentViewDisplayTime, nextViewDisplayTime } = getTeacherViewEventDisplayTime(events?.view, {
    templateId: item?.id
  })

  return {
    ...item,
    currentViewDisplayTime,
    nextViewDisplayTime
  }
}

export default structureDividerTemplateMapper
