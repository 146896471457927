const getEnvironment = () => {
  const environment = process.env.REACT_APP_ENV
  const isNodeEnvDev = process.env.NODE_ENV === 'development'

  const isProduction = environment === 'production' && !isNodeEnvDev
  const isStage = environment === 'staging' && !isNodeEnvDev
  const isDevelopment = environment === 'development' || isNodeEnvDev

  return { environment, isProduction, isStage, isDevelopment }
}

export default getEnvironment
